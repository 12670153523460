import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '../TextField';
import { styles } from './style';

export const DateSelector = ({
  name,
  label,
  defaultValue,
  onFocus,
  helperText,
  error,
  disabled,
  cssStyle,
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue && !error ? defaultValue : null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={selectedDate}
        onChange={setSelectedDate}
        onAccept={onFocus}
        disabled={disabled}
        label={label}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              cssStyle={cssStyle}
              error={error}
              helperText={helperText}
              name={name}
            />
          );
        }}
        InputProps={{ onFocus }}
        PaperProps={{ sx: styles.paper }}
      />
    </LocalizationProvider>
  );
};

DateSelector.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onFocus: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
