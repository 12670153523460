import React from 'react';
import PropTypes from 'prop-types';
import {
  INITIAL,
  RECORDING,
  UPLOADING,
  COUNTDOWN,
  RESPONSE_PREVIEW,
} from '../../index';
import Countdown from '../Countdown';
import { PrimaryButton } from '../../../Button';
import { updateMedia } from '../../../api';
import {
  setButtonsWrapperClassName,
  setHelpTextClassName,
  setCountdownClassName,
  buildParams,
} from '../../utils';
import UploadButtons from '../../../UploadMediaSection/components/UploadButtons';
import UploadingState from '../../../UploadMediaSection/components/UploadingState';

function ButtonsSection({
  blob,
  uuid,
  uploadUrl,
  askerSide,
  recordingState,
  setRecordingState,
  setSignedIdObj,
  startRecording,
  stopRecording,
  resetToInitial,
  closeRecordPage,
  ctaButtonContent,
  ctaButtonStyle,
}) {
  const uploadCreateCallback = (error, { signed_id }) => {
    if (error) {
      resetToInitial();
    } else {
      const params = buildParams(askerSide, signed_id);

      if (askerSide) {
        updateMedia(uuid, params).then((response) => {
          if (response.ok) {
            closeRecordPage();
          }
        });
      } else {
        setSignedIdObj({ name: 'video_signed_id', value: signed_id });
        setRecordingState(RESPONSE_PREVIEW);
      }
    }
  };

  const renderContent = () => {
    switch (recordingState) {
      case INITIAL:
        return (
          <>
            <h3 className={setHelpTextClassName(askerSide)}>
              When you're ready, press Start Recording
            </h3>
            <div className={setButtonsWrapperClassName(askerSide)}>
              <button
                className="button media-background__button media-background__button--cancel"
                type="button"
                data-testid="cancel-upload-button"
                onClick={closeRecordPage}
              />
              <PrimaryButton
                className="media-background__button media-background__button--record"
                data-testid="record-button"
                onClick={startRecording}
                style={{ display: 'flex' }}
                {...(ctaButtonStyle && ctaButtonStyle)}
              >
                Start Recording
              </PrimaryButton>
            </div>
          </>
        );
      case COUNTDOWN:
        return (
          <Countdown
            initialValue={3}
            onTimerEnd={() => setRecordingState(RECORDING)}
            className={setCountdownClassName(askerSide)}
          />
        );
      case RECORDING:
        return (
          <>
            <Countdown
              initialValue={59}
              formatTimer
              onTimerEnd={stopRecording}
              className={setHelpTextClassName(askerSide)}
            />
            <div className={setButtonsWrapperClassName(askerSide)}>
              <PrimaryButton
                className="media-background__button"
                data-testid="record-button"
                onClick={stopRecording}
                {...(ctaButtonStyle && ctaButtonStyle)}
              >
                Stop Recording
              </PrimaryButton>
            </div>
          </>
        );
      case UPLOADING:
        return (
          <UploadingState
            file={blob}
            uploadUrl={uploadUrl}
            refreshState={resetToInitial}
            uploadCreateCallback={uploadCreateCallback}
          />
        );
      case RESPONSE_PREVIEW:
        return (
          <div className={setButtonsWrapperClassName(askerSide)}>
            <UploadButtons
              onCancel={resetToInitial}
              ctaButtonContent={ctaButtonContent}
              ctaButtonStyle={ctaButtonStyle}
            />
          </div>
        );
    }
  };

  return <>{renderContent()}</>;
}

ButtonsSection.propTypes = {
  blob: PropTypes.object,
  askerSide: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string.isRequired,
  recordingState: PropTypes.string.isRequired,
  setRecordingState: PropTypes.func.isRequired,
  startRecording: PropTypes.func.isRequired,
  stopRecording: PropTypes.func.isRequired,
  resetToInitial: PropTypes.func.isRequired,
  closeRecordPage: PropTypes.func.isRequired,
  ctaButtonContent: PropTypes.string,
};

export default ButtonsSection;
