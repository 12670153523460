import React from 'react';
import PropTypes from 'prop-types';
import { readableColor, parseToRgb } from 'polished';
import { Container, Link } from './style';

function BrandedLabel({
  isHidden,
  title,
  link,
  withMediaBackground,
  contentLayout,
  backgroundColorOverlay,
}) {
  const color = React.useMemo(() => {
    if (
      !backgroundColorOverlay ||
      backgroundColorOverlay.includes('var(--') ||
      parseToRgb(backgroundColorOverlay)?.alpha <= 0.5
    ) {
      return '#fff';
    } else {
      return readableColor(backgroundColorOverlay);
    }
  }, [backgroundColorOverlay]);

  return (
    <Container data-testid="BrandedLabel/Container">
      {!isHidden && (
        <Link
          target="_blank"
          href={link}
          color={color}
          withMediaBackground={withMediaBackground && contentLayout !== 'hug_content'}
          data-testid="BrandedLabel/Anchor"
          data-css-selector="branded label anchor"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
    </Container>
  );
}

BrandedLabel.propTypes = {
  isHidden: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.string,
  withMediaBackground: PropTypes.bool,
};

export default BrandedLabel;
