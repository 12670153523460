import styled from 'styled-components';

export const Label = styled.div`
  position: relative;
  color: #fff;
`;

export const MarginTop = styled.div`
  position: relative;
  margin-top: 0.5rem;
  z-index: 1;
`;

export const ErrorMessage = styled.span`
  border-radius: 5px;
  text-align: center;
  background-color: #e74c0a;
  font-size: 12px;
  padding: 0 12px;
  position: absolute;
  right: 0;
  transform: translateY(-10px);
  z-index: 2;
`;
