import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MultiSelectOptionButton,
  ActionButton,
  CancelButton,
  EditButton,
  OtherButtonInput,
  OtherButtonContainer,
  InnerContentContainerForButton,
  ErrorMessage,
} from './style';
import Checkbox from './Checkbox';

const disabledOtherButton = (
  <MultiSelectOptionButton disabled={true}>
    + Add Option
  </MultiSelectOptionButton>
);

function MultiSelectOtherButton({
  disabled,
  error,
  onOtherNameChange,
  value,
  otherOptionId,
  shouldBeOpened,
}) {

  const [otherName, setOtherName]       = useState(value);
  const [isEditing, setIsEditing]       = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);
  const [checked, setChecked]           = useState(shouldBeOpened || !!error);

  // Update errorMessage from props
  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const handleChange = ({ target: { value } }) => {
    setOtherName(value);

    // Hide error message on typing
    setErrorMessage(null);
  };

  const isValid = () => {
    if (String(otherName).trim()) {
      return true;
    } else {
      return false;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      // Validate value
      if (isValid()) {
        // Exit from Editing state
        setIsEditing(false);
        setChecked(true);

        // pass otherOption to parent's state
        onOtherNameChange([otherOptionId, otherName]);
      } else {
        setErrorMessage('Response required');
      }
    }
  };

  const handleChecked = (e) => {
    e.preventDefault();

    if (checked) {
      setChecked(false);

      // pass otherOption to parent's state
      onOtherNameChange([]);
    } else {
      setChecked(true);

      // pass otherOption to parent's state
      onOtherNameChange([otherOptionId, otherName]);
    }
  };

  const startEditing = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsEditing(true);
  };

  const cancelEditing = (e) => {
    e.preventDefault();

    setIsEditing(false);

    // pass otherOption to parent's state
    onOtherNameChange([]);
  };

  const saveValue = (e) => {
    e.preventDefault();

    // Validate value
    if (isValid()) {
      // Exit from Editing state
      setIsEditing(false);
      setChecked(true);

      // pass otherOption to parent's state
      onOtherNameChange([otherOptionId, otherName]);
    } else {
      setErrorMessage('Response required');
    }
  };

  const renderButton = () => {
    if (isEditing) {
      return (
        <OtherButtonContainer>
          <OtherButtonInput
            placeholder='Type Other'
            value={otherName}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            autoFocus={true}
            data-testid='otherButton/input'
          />
          { errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage> }
          { (otherName.trim()) ?
            <ActionButton
              onClick={saveValue}
              data-testid='otherButton/saveButton'
            >
              Save
            </ActionButton> :
            <CancelButton
              onClick={cancelEditing}
              data-testid='otherButton/cancelButton'
            >
              Cancel
            </CancelButton>
          }
        </OtherButtonContainer>
      );
    }

    if (!isEditing && otherName) {
      return (
        <OtherButtonContainer>
          { errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage> }
          <MultiSelectOptionButton
            role="button"
            onClick={handleChecked}
            checked={checked}
            data-testid='otherButton/preview'
          >
            <Checkbox checked={checked} />
            <InnerContentContainerForButton>
              {otherName}
            </InnerContentContainerForButton>
            <EditButton
              onClick={startEditing}
              data-testid='otherButton/editButton'
            >
              Edit
            </EditButton>
          </MultiSelectOptionButton>
        </OtherButtonContainer>
      );
    }

    if (!isEditing && !otherName) {
      return (
        <MultiSelectOptionButton
          role="button"
          onClick={startEditing}
        >
          + Add Option
        </MultiSelectOptionButton>
      );
    }
  };

  if (disabled) {
    return disabledOtherButton;
  } else {
    return renderButton();
  }
};

MultiSelectOtherButton.propTypes = {
  shouldBeOpened: PropTypes.bool,
  disabled: PropTypes.bool,
  otherOptionId: PropTypes.number,
  onOtherNameChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};

export default MultiSelectOtherButton;
