import React from 'react';
import PropTypes from 'prop-types';

import { Container, ButtonWrapper, RadioButton, RadioLabel } from './style';

const radioButtons = [
  { label: 'Monthly', defaultValue: 'true' },
  { label: 'One Time', defaultValue: 'false' },
];

function DonationTypeSelector({ setDonationType, value, disabled }) {
  const handleChange = (e) => {
    setDonationType(e.currentTarget.value);
  };

  return (
    <Container data-testid="DonationAmountsSelector/DonationType">
      {radioButtons.map(({ label, defaultValue }) => (
        <ButtonWrapper key={label}>
          <RadioButton
            checked={value === defaultValue}
            type="radio"
            value={defaultValue}
            id={`donation_amount_response_recurring_${defaultValue}`}
            onChange={handleChange}
          />
          <RadioLabel
            htmlFor={`donation_amount_response_recurring_${defaultValue}`}
            disabled={disabled}
          >
            {label}
          </RadioLabel>
        </ButtonWrapper>
      ))}
    </Container>
  );
}

DonationTypeSelector.propTypes = {
  setDonationType: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default DonationTypeSelector;
