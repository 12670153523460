// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

if (process.env.RAILS_ENV === 'production') {
  application.handleError = (error, message, detail) => {
    // eslint-disable-next-line no-console
    console.warn(message, detail);
    window.exceptionHandler.notify(error, {
      component: detail.identifier,
      message,
    });
  };
}
