import React from 'react';
import LazyImage from '../../../LazyImage';

export default function BackgroundImage({
  imageUrl,
  placeholderImageUrl,
  backgroundMediaOpacity,
}) {
  if (placeholderImageUrl) {
    return (
      <LazyImage
        data-testid="BackgroundOverlay/Image"
        src={placeholderImageUrl}
        data-src={imageUrl}
        style={{ opacity: backgroundMediaOpacity }}
      />
    );
  }
  return (
    <img
      data-testid="BackgroundOverlay/Image"
      src={imageUrl}
      style={{ opacity: backgroundMediaOpacity }}
    />
  );
}
