import React from 'react';
import PropTypes from 'prop-types';
import { Container, SubmitButton } from './style';

function SkipButton({ text, expResponseId, responseId, isVisible }) {
  if (!isVisible) {
    return null;
  }

  const formRef = React.useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;

    form.requestSubmit(e.currentTarget);
  };

  return (
    <Container>
      <form
        action={`/experience_responses/${expResponseId}/responses/${responseId}/skip`}
        method="POST"
        ref={formRef}
      >
        <input name="_method" type="hidden" value="put" />

        <SubmitButton
          data-testid="SkipButton/Submit"
          type="submit"
          disabled={!expResponseId}
          onClick={handleSubmit}
        >
          {text}
        </SubmitButton>
      </form>
    </Container>
  );
}

SkipButton.propTypes = {
  text: PropTypes.string,
  expResponseId: PropTypes.string,
  responseId: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default SkipButton;
