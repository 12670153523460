import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto 0.5rem auto;
  padding: 0;
  max-width: 17.5rem;
  font-size: 0.8125rem;
  line-height: 18px;
  font-weight: 500;
`;

export const ButtonWrapper = styled.span`
  width: 100%;

  &:first-of-type label {
    border-radius: 8px 0px 0px 8px;
    border-right-width: 0px;
  }

  &:nth-of-type(2) label {
    border-radius: 0px 8px 8px 0px;
    border-left-width: 0px;
  }
`;

export const RadioButton = styled.input`
  display: none;

  &:checked ~ label {
    background-color: #fff;
    color: #000;
  }
`;

export const RadioLabel = styled.label`
  margin: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  letter-spacing: -0.08px;
  color: rgba(255, 255, 255, 0.8);
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};

  &:hover {
    cursor: pointer;
    border: 1px solid #fff;
    color: #fff;
  }
`;
