import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';

const halfWidthTypes = ['first_name', 'last_name'];
const typesMapper = {
  first_name: 'last_name',
  last_name: 'first_name',
};
const setType = (field) => {
  if (field) {
    return field.attributes?.type || field.type;
  }
};

export const isHalfWidth = (current, prev, next) => {
  const currentType = setType(current);

  if (!halfWidthTypes.includes(currentType)) {
    return false;
  }

  const nextType = setType(next);
  const prevType = setType(prev);

  return (
    typesMapper[currentType] === nextType ||
    typesMapper[currentType] === prevType
  );
};

const initialCssStyle = {
  backgroundColor: 'rgba(255,255,255,1)',
  borderColor: 'rgba(255,255,255,1)',
  borderWidth: '0px',
  borderStyle: 'solid',
  boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 4px',
  borderRadius: '8px',
};

const checkboxInitialCssStyle = {
  backgroundColor: 'rgba(255,255,255,0)',
  borderColor: 'rgba(255,255,255,0)',
  borderWidth: '0px',
  borderStyle: 'solid',
  borderRadius: '8px',
};

export const buildCssStyle = (type, cssStyle) => ({
  ...(type === 'checkbox' ? checkboxInitialCssStyle : initialCssStyle),
  ...(!_isEmpty(cssStyle) && _omitBy(cssStyle, _isEmpty)),
});

