import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import UploadingState from '../../UploadMediaSection/components/UploadingState';
import { setClassName } from '../../../utils';
import makeToast from '../../toast';
import { uploadMediaValidator } from '../../UploadMediaSection/uploadMediaValidator';
import _isEmpty from 'lodash/isEmpty';
import MediaPreview from './components/MediaPreview';
import ResponsePreview from './components/ResponsePreview';
import UploadButtons from '../../UploadMediaSection/components/UploadButtons';
import {
  ALL_ALLOWED_FILE_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_VIDEO_TYPES,
} from '../../../constants';

export const setDefaultSignedIdObj = (mediaResponse) => {
  if (mediaResponse.latest_attachment) {
    const { latest_attachment } = mediaResponse;
    return {
      name: `${latest_attachment.type}_signed_id`,
      value: latest_attachment.signed_id,
    };
  } else {
    return {};
  }
};

function UgcUploadResponse({
  directUploadUrl,
  backgroundLayout,
  backgroundIsColor,
  ctaButtonContent,
  ctaButtonStyle,
  mediaResponse = {},
}) {
  const [files, setFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [signedIdObj, setSignedIdObj] = React.useState(
    setDefaultSignedIdObj(mediaResponse),
  );
  const [responses, setResponses] = React.useState(mediaResponse);

  const signedIdIsEmpty = React.useMemo(() => {
    return _isEmpty(signedIdObj);
  }, [signedIdObj]);

  const refreshState = () => {
    setFiles([]);
    setIsUploading(false);
    setResponses({});
    setSignedIdObj({});
  };

  const onDropAccepted = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    setIsUploading(true);
  };

  const onDropRejected = (rejectedFiles) => {
    rejectedFiles.map(({ errors }) => {
      const errorMessage = errors[1] ? errors[1].message : errors[0].message;

      makeToast(errorMessage, 'error', 3000, 0);
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ALL_ALLOWED_FILE_TYPES,
    noClick: true,
    multiple: false,
    validator: (file) => uploadMediaValidator(file, true),
    onDropAccepted,
    onDropRejected,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  useEffect(() => {
    /* We're using oldschool logic for setting onclick function and hiding stuff
       from another component because of current app architecture.
      Will change it after SPA */

    const responderVideoPlayer = document.querySelector(
      '.responder-background-video-wrapper',
    );
    const backgroundVideoTag = document.querySelector(
      '.responder-background-video-wrapper .video-react-video',
    );
    const uploadButton = document.querySelector(
      "[data-testid='UgcSelector/UploadResponseButton']",
    );
    const ugcSelectorsWrapper = document.querySelector(
      '.ugc-selector__wrapper',
    );

    const uploadedFilePreview = isUploading || !signedIdIsEmpty;

    if (responderVideoPlayer) {
      if (uploadedFilePreview) {
        responderVideoPlayer.classList.add('hide-on-mobile');
        backgroundVideoTag.pause();
      } else {
        responderVideoPlayer.classList.remove('hide-on-mobile');
      }
    }

    if (uploadButton) {
      uploadButton.onclick = open;
    }

    if (ugcSelectorsWrapper) {
      if (uploadedFilePreview) {
        ugcSelectorsWrapper.classList.add('hidden');
      } else {
        ugcSelectorsWrapper.classList.remove('hidden');
      }
    }
  }, [isUploading, signedIdIsEmpty]);

  const uploadCreateCallback = (error, { content_type, signed_id }) => {
    if (error) {
      setIsUploading(false);
    } else {
      let signedIdPropertyName;

      if (ALLOWED_IMAGE_TYPES.includes(content_type)) {
        signedIdPropertyName = 'image_signed_id';
      } else if (ALLOWED_VIDEO_TYPES.includes(content_type)) {
        signedIdPropertyName = 'video_signed_id';
      } else {
        signedIdPropertyName = 'file_object_signed_id';
      }
      setIsUploading(false);
      setSignedIdObj({ name: signedIdPropertyName, value: signed_id });
    }
  };

  return (
    <>
      <div {...getRootProps()}>
        <input
          data-testid="UgcUploadResponse/UploadInput"
          {...getInputProps()}
        />
        {!signedIdIsEmpty && _isEmpty(files) && (
          <div
            className={`upload-media-wrapper ${setClassName(
              'upload-media-wrapper--color-background',
              backgroundIsColor,
            )}`}
          >
            <ResponsePreview
              attachment={responses.latest_attachment}
              backgroundLayout={backgroundLayout}
              backgroundIsColor={backgroundIsColor}
            />
          </div>
        )}
        {!_isEmpty(files) && (
          <div
            className={`upload-media-wrapper ${setClassName(
              'upload-media-wrapper--color-background',
              backgroundIsColor,
            )}`}
          >
            <MediaPreview
              files={files}
              backgroundLayout={backgroundLayout}
              backgroundIsColor={backgroundIsColor}
            />
          </div>
        )}
      </div>
      <div className="ha-form__record-button-wrapper">
        <UploadingState
          file={files[0]}
          hidden={!isUploading}
          uploadCreateCallback={uploadCreateCallback}
          uploadUrl={directUploadUrl}
          refreshState={refreshState}
        />
        {!signedIdIsEmpty && (
          <UploadButtons
            onCancel={refreshState}
            ctaButtonContent={ctaButtonContent}
            ctaButtonStyle={ctaButtonStyle}
          />
        )}
      </div>
      {!signedIdIsEmpty && (
        <input
          type="hidden"
          name={`ugc_media_response[${signedIdObj.name}]`}
          value={signedIdObj.value}
        />
      )}
    </>
  );
}

UgcUploadResponse.propTypes = {
  directUploadUrl: PropTypes.string.isRequired,
  backgroundLayout: PropTypes.string,
  backgroundIsColor: PropTypes.bool,
  ctaButtonContent: PropTypes.string,
};

export default UgcUploadResponse;
