import React from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from '../VideoPlayer';
import { VideoContainer } from './style';

function VideoBackground({
  sources,
  backgroundIsColor,
  hidePlayButton,
  backgroundLayout,
  withShareIcon,
  onVideoPlaying,
  onPlayButtonClick,
  hiddenOnMobile,
  placeholderImageUrl,
}) {
  return (
    <VideoContainer
      className={hiddenOnMobile ? 'hide-on-mobile' : ''}
      backgroundIsColor={backgroundIsColor}
    >
      <VideoPlayer
        sources={sources}
        hidePlayButton={hidePlayButton}
        backgroundLayout={backgroundLayout}
        withShareIcon={withShareIcon}
        onVideoPlaying={onVideoPlaying}
        onPlayButtonClick={onPlayButtonClick}
        placeholderImageUrl={placeholderImageUrl}
      />
    </VideoContainer>
  );
}

VideoBackground.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  backgroundIsColor: PropTypes.bool,
  hidePlayButton: PropTypes.bool,
  backgroundLayout: PropTypes.string,
  withShareIcon: PropTypes.bool,
  onVideoPlaying: PropTypes.func,
};

export default VideoBackground;
