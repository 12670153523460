import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import styled from 'styled-components';

const ToastWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 1rem;
  color: var(--white);
  text-align: center;
  padding: 1rem 0.5rem;
  background-color: ${(props) => props.color};
  z-index: 3;
  transform: translateY(${(props) => props.top}px);

  a {
    color: var(--white);

    &:hover {
      color: var(--white);
    }
  }
`;

const DEFAULT_TIMEOUT = 3000;
const DEFAULT_TOP_OFFSET = 60;
const DEFAULT_TOAST_ID = 'toast';

function getToastOffset(topOffset) {
  const currentPosition = window.pageYOffset;

  if (currentPosition >= topOffset) {
    return currentPosition;
  } else {
    return topOffset;
  }
}

function Toast({ text, color, topOffset }) {
  const [top, setTop] = useState(getToastOffset(topOffset));

  useEffect(() => {
    window.addEventListener('scroll', () => setTop(getToastOffset(topOffset)));

    return () => {
      window.removeEventListener('scroll');
    };
  }, []);

  return (
    <ToastWrapper top={top} color={color}>
      {text}
    </ToastWrapper>
  );
}

function findOrCreateElement(id) {
  let element = document.getElementById(id);

  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', id);

  document.body.insertBefore(
    element,
    document.body.lastElementChild.nextElementSibling,
  );

  return element;
}

function backgroundColorByType(type) {
  if (type === 'success') {
    return 'var(--success)';
  } else if (type === 'info') {
    return '#66AEF9';
  } else if (type === 'error') {
    return 'var(--error)';
  }
}

function makeToast(
  text,
  type = 'success',
  timeout = DEFAULT_TIMEOUT,
  topOffset = DEFAULT_TOP_OFFSET,
) {
  const element = findOrCreateElement(DEFAULT_TOAST_ID);

  if (timeout) {
    setTimeout(() => element.remove(), timeout);
  }

  const color = backgroundColorByType(type);

  render(<Toast text={text} color={color} topOffset={topOffset} />, element);

  return element;
}

export default makeToast;
