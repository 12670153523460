import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';

import Button from './components/Button';
import OtherButton from './components/OtherButton';
import MultiSelectOtherButton from './components/MultiSelectOtherButton';

import wretch from 'wretch';
import CtaButton from '../../../../shared/CtaButton';

const prepareErrorMessage = (message) => {
  let localError = null;
  let globalError = null;

  const errors = JSON.parse(message).errors;
  const responsesErrors = errors.multiple_choice_option_responses;

  if (_isArray(responsesErrors)) {
    globalError = responsesErrors[0];
  } else {
    localError = responsesErrors.multiple_choice_option_name[0];
  }

  return [localError, globalError];
};

function ButtonsSelector(props) {
  const {
    responseId,
    options,
    currentValues: defaultValues,
    otherOptionId,
    withOtherOption,
    disabled,
    isMulti,
    setErrorMessage: setParentErrorMessage,
    ctaButtonContent,
    ctaButtonStyle,
    isPreview,
  } = props;
  const [currentValues, setCurrentValues] = useState(defaultValues);

  const findOtherValue = () =>
    defaultValues.find(([id, _]) => id === otherOptionId);
  let otherValue = findOtherValue();
  otherValue = otherValue ? otherValue[1] : '';

  const [otherOption, setOtherOption] = useState(
    otherValue ? [otherOptionId, otherValue] : [],
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const handleButtonClick = (value) => {
    handleCurrentValuesChange(value);
  };

  const handleCurrentValuesChange = (nextValue) => {
    let nextState;
    if (isMulti) {
      const [id, name] = nextValue;
      const isContain = currentValues.find(([cId, _]) => cId === id);

      if (isContain) {
        nextState = currentValues.filter(([cId, _]) => cId !== id);
      } else {
        nextState = [...currentValues, nextValue];
      }
    } else {
      nextState = [nextValue];
    }

    setCurrentValues(nextState);
    setParentErrorMessage(null);
  };

  const handleOtherNameChange = (option) => {
    setOtherOption(option);
    setParentErrorMessage(null);
  };

  const submitResponse = (e) => {
    e.preventDefault();

    let allOptions = [...currentValues];
    // Remove previous OtherOption from array
    const newArray = allOptions.filter((el) => el[0] !== otherOptionId);
    allOptions = newArray;

    if (otherOption.length > 0) {
      // Add current OtherOption to array
      allOptions.push(otherOption);
    }

    const params = {
      response: {
        multiple_choice_option_responses: allOptions.map((option) => {
          return {
            multiple_choice_option_name: option[1],
            multiple_choice_option_id: option[0],
          };
        }),
      },
    };

    wretch(`/api/v1/responses/${responseId}/multiple_choices`)
      .content('application/json')
      .put(params)
      .json((json) => {
        Turbo.visit(json.redirect_url);
      })
      .catch((error) => {
        const [localMessage, globalMessage] = prepareErrorMessage(
          error.message,
        );

        setErrorMessage(localMessage);
        setParentErrorMessage(globalMessage);
      });
  };

  const isSelected = (id) => defaultValues[0] && defaultValues[0][0] === id;

  return (
    <>
      {options.map((value, index) => {
        return (
          <Button
            key={`button-${index}`}
            responseId={responseId}
            option={[value[0], value[1], value[2]]}
            cssStyle={value[3] || {}}
            disabled={disabled}
            isMulti={isMulti}
            isPreview={isPreview}
            selected={isSelected(value[0])}
            checked={
              isMulti && !!currentValues.find(([id, _]) => value[0] === id)
            }
            onClick={handleButtonClick}
          />
        );
      })}
      {withOtherOption && isMulti && (
        <MultiSelectOtherButton
          disabled={disabled}
          value={otherValue}
          shouldBeOpened={
            !!currentValues.find(([id, _]) => otherOptionId === id)
          }
          error={errorMessage}
          otherOptionId={otherOptionId}
          onOtherNameChange={handleOtherNameChange}
        />
      )}
      {withOtherOption && !isMulti && (
        <OtherButton
          disabled={disabled}
          selected={isSelected(otherOptionId)}
          responseId={responseId}
          currentValue={otherValue}
          otherOptionId={otherOptionId}
        />
      )}
      {isMulti && (
        <CtaButton
          onClick={submitResponse}
          disabled={disabled}
          data-testid="SelectBoxSelector/submit"
          ctaButtonContent={ctaButtonContent}
          ctaButtonStyle={ctaButtonStyle}
          isPreview={isPreview}
        />
      )}
    </>
  );
}

ButtonsSelector.propTypes = {
  currentValues: PropTypes.array,
  otherOptionId: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  withOtherOption: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  ctaButtonContent: PropTypes.string.isRequired,
};

export default ButtonsSelector;
