import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import useUpdateReferencesTags from '../../hooks/useUpdateReferencesTags';
import { toHtmlWithStyledReferences } from '../../../utils';
import { SubmissionButton } from '../../Button';
import withStyledButtonText from '../hoc/withStyledButtonText';

function CtaButton({ ctaButtonContent, ctaButtonStyle, isPreview, ...rest }) {
  const parsedHtml = isPreview && useUpdateReferencesTags(ctaButtonContent);
  const styledContent = isPreview && toHtmlWithStyledReferences(parsedHtml);
  const styles = _omitBy(ctaButtonStyle, _isEmpty) || {};

  return (
    <SubmissionButton
      data-css-selector="cta button"
      aria-label="submission button"
      styles={styles}
      {...rest}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: styledContent || ctaButtonContent,
        }}
      />
    </SubmissionButton>
  );
}

export default withStyledButtonText(CtaButton);
