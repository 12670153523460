import { useState, useEffect, useContext } from 'react';
import ReferencesContext, {
  ADD_REFERENCE,
} from '../../contexts/ReferencesContext';

function useUpdateReferencesTags(html) {
  const [{ references }, dispatch] = useContext(ReferencesContext);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const parsedText = html?.split(/({{.*?}})/g).map((text) => {
      if (!text.match(/{{(?!#|\/).*}}/)) {
        return text;
      }

      const ref = references[text];

      if (!ref) {
        dispatch({
          type: ADD_REFERENCE,
          payload: {
            reference: text,
          },
        });
      }

      return ref
        ? { name: ref.name, reference: ref.reference }
        : { name: 'Loading...' };
    });

    setContent(parsedText);
  }, [html, references]);

  return content;
}

export default useUpdateReferencesTags;
