import React from 'react';
import Box from '@mui/material/Box';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import Typography from '@mui/material/Typography';
import SignaturePad from './SignaturePad';
import ClearIcon from '@mui/icons-material/Clear';
import { DirectUpload } from '@rails/activestorage';
import { ErrorMessage } from '../../responder/MultipleChoiceSelector/components/ButtonsSelector/components/style';

export default function SignatureField({
  helperText,
  name,
  defaultValue,
  cssStyle,
  isPreview,
  label,
}) {
  const [errorMessage, setErrorMessage] = React.useState(helperText);
  const [signedId, setSignedId] = React.useState(defaultValue?.signed_id || '');
  const [open, setOpen] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [svg, setSvg] = React.useState();
  const canvasRef = React.useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClearPad = () => {
    canvasRef.current.clear();
  };
  const handleSaveSignature = () => {
    if (canvasRef.current.isEmpty()) {
      setSignedId('');
      setIsEmpty(true);
      handleClose();
    } else {
      const canvas = canvasRef.current;
      const svg = canvas.toSVG();
      setSvg(svg);

      const timestamp = new Date().getTime();
      const file = new File([svg], `signature_${timestamp}.svg`, {
        type: 'image/svg+xml',
      });

      setIsUploading(true);
      const upload = new DirectUpload(
        file,
        '/rails/active_storage/direct_uploads',
      );
      upload.create((error, blob) => {
        if (error) {
          setErrorMessage(error);
        } else {
          setSignedId(blob.signed_id);
        }

        handleClose();
        setIsEmpty(false);
        setIsUploading(false);
      });
    }
  };

  return (
    <>
      <Box sx={{ color: cssStyle.color || 'inherit' }}>{label}</Box>
      <Box
        sx={{
          position: 'relative',
          color: 'rgba(0, 0, 0, 0.6)',
          pointerEvents: isPreview ? 'none' : 'auto',
          ...cssStyle,
          ...(errorMessage && {
            borderColor: 'var(--error)',
            borderWidth: cssStyle.borderWidth === '0px'
              ? '1.5px'
              : cssStyle.borderWidth,
          }),
        }}
      >
        {errorMessage && (
          <ErrorMessage
            style={{
              color: '#fff',
              background: 'var(--error)',
              top: '-10px',
              right: '3px',
              padding: '2px 8px',
              marginTop: 0,
            }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            py: 4,
          }}
        >
          {isEmpty
            ? (
              <>
                <DrawOutlinedIcon
                  aria-label="open signature pad"
                  sx={{ fontSize: 50, cursor: 'pointer' }}
                  onClick={handleOpen}
                />
                <Typography variant="body2">
                  Click here to sign.
                </Typography>
              </>
            )
            : (
              <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
                <Box
                  sx={{
                    height: 70,
                    '& svg': {
                      maxHeight: '100%',
                      maxWidth: '100%',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: svg }}
                />
                <ClearIcon
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '50%',
                    right: '16px',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => {
                    setSignedId('');
                    setIsEmpty(true);
                  }}
                />
              </Box>
            )}
        </Box>
      </Box>
      {!isPreview && (
        <SignaturePad
          open={open}
          handleClose={handleClose}
          onClear={handleClearPad}
          onSave={handleSaveSignature}
          canvasRef={canvasRef}
          isUploading={isUploading}
        />
      )}
      <input type="hidden" name={name} defaultValue={signedId} />
    </>
  );
}
