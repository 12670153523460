import { inputLabelClasses } from '@mui/material/InputLabel';

export const styles = {
  formControl: {
    width: '100%',
    '& .MuiInput-formControl': {
      marginTop: 0,
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-root': {
      position: 'relative',
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      fontSize: '12px',
      transform: 'translate(16px, -12px)',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#ff432e',
    },
    '& .MuiInputBase-root.Mui-error .MuiInputBase-input, & .MuiInputBase-root.Mui-error .MuiNativeSelect-select.MuiNativeSelect-select:hover': {
      border: '1.5px solid #ff432e',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#fff',
    },
    '& .MuiNativeSelect-select.MuiNativeSelect-select': {
      paddingRight: '44px',
    },
  },
  inputLabel: {
    width: 'calc(100% - 60px)',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    fontWeight: 500,
    margin: '14px 0 8px',
    zIndex: 1,
    pointerEvents: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'transform 0.2s, font-size 0.2s',
    [`&.${inputLabelClasses.shrink}`]: {
      fontSize: '12px',
      transform: 'translate(16px, -12px)',
      marginTop: '14px',
    },
    [`&.${inputLabelClasses.error}`]: {
      fontSize: '12px',
      transform: 'translate(16px, -12px)',
      marginTop: '14px',
    },
  },
  select: {
    '& .MuiInputBase-input': {
      height: '1.1876em',
      lineHeight: '1.1876em',
      background: '#fff',
      padding: '20px 16px 6px',
      borderRadius: '8px',
      border: '1.5px solid',
      borderColor: '#f2f2f2',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
      '&:hover': {
        borderColor: '#f2f2f2',
      },
      '&:disabled': {
        '&:hover': {
          border: '1.5px solid',
          borderColor: '#f2f2f2',
        },
      },
      '&:focus': {
        background: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
      },
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#e6e6e6',
    },
  },
  formHelperText: {
    position: 'absolute',
    top: '-10px',
    right: 0,
    marginRight: '6px',
    padding: '2px 8px',
    backgroundColor: '#ff432e',
    borderRadius: '6px',
    zIndex: 1,
  },
};

export const getCustomizedFormControlsStyles = ({ color, ...cssStyle }) => ({
  '& .MuiNativeSelect-select': {
    color: color || 'rgba(0, 0, 0, 0.87)',
    ...cssStyle,
    m: 0,
  },
  '& .MuiNativeSelect-select.MuiNativeSelect-select, & .MuiNativeSelect-select.MuiNativeSelect-select:hover, & .MuiNativeSelect-select.MuiNativeSelect-select:disabled, & .MuiNativeSelect-select.MuiNativeSelect-select:focus': {
    ...cssStyle,
    m: 0,
  },
});

export const getCustomizedNativeSelectStyles = ({ color, ...cssStyle }) => {
  const disabledTextColor = color || 'rgba(0, 0, 0, 0.38)';

  return {
    '& .MuiNativeSelect-select': {
      color: color || 'rgba(0, 0, 0, 0.87)',
      ...cssStyle,
      m: 0,
    },
    '& .MuiNativeSelect-select:disabled': {
      ...cssStyle,
      m: 0,
    },
    '&.MuiInputBase-root .Mui-disabled': {
      ...(cssStyle && { backgroundColor: 'transparent' }),
    },
    '& .MuiNativeSelect-select:disabled:hover, & .MuiNativeSelect-select:hover, & .MuiNativeSelect-select:active, & .MuiNativeSelect-select:focus': {
      ...cssStyle,
      m: 0,
    },
    '& .MuiNativeSelect-icon': {
      top: 'unset',
      right: 0,
      marginRight: '16px',
      color: color || 'rgba(0, 0, 0, 0.54)',
      opacity: color ? 0.75 : 1,
    },
    '& .MuiNativeSelect-icon.Mui-disabled': {
      color: color || 'rgba(0, 0, 0, 0.26)',
      opacity: color ? 0.4 : 1,
    },
    '& .MuiNativeSelect-select.MuiInputBase-input.Mui-disabled': {
      color: disabledTextColor,
      WebkitTextFillColor: disabledTextColor,
    },
  };
};
