import React from 'react';
import Modal from '@mui/material/Modal';
import SignatureCanvas from 'react-signature-pad-wrapper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

export default function SignaturePad({
  open,
  handleClose,
  onSave,
  onClear,
  canvasRef,
  isUploading,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          maxWidth: 700,
          maxHeight: 440,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 2,
        }}
        tabIndex="none"
      >
        <Box
          sx={{
            border: 1,
            borderColor: 'var(--border-color-primary)',
            flex: 1,
            display: 'flex',
            position: 'relative',

            '& canvas': {
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            },
          }}
        >
          <SignatureCanvas ref={canvasRef} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            disableFocusRipple
            disableElevation
            disableTouchRipple
            variant="outlined"
            disabled={isUploading}
            sx={{
              fontFamily: 'var(--font-family)',
              width: 'auto',
              mr: 1,
              borderColor: 'var(--primary-color)',
              color: 'var(--primary-color)',
              textTransform: 'capitalize',

              '&:hover': {
                borderColor: 'var(--primary-colorj-darken)',
                color: 'var(--primary-color-darken)',
                bgcolor: 'white',
              },
            }}
            onClick={onClear}
          >
            Clear
          </Button>
          <LoadingButton
            disableFocusRipple
            disableElevation
            disableTouchRipple
            variant="contained"
            loading={isUploading}
            sx={{
              fontFamily: 'var(--font-family)',
              width: 'auto',
              color: 'white',
              bgcolor: 'var(--primary-color)',
              textTransform: 'capitalize',

              '&:hover': {
                color: 'white',
                bgcolor: 'var(--primary-color-darken)',
              },
            }}
            onClick={onSave}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
