import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const DataFieldContainer = styled.div`
  display: ${(props) => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  flex: ${(props) => (props.halfWidth ? '1 1 50%' : ' 1 0 100%')};
  max-width: ${(props) => (props.halfWidth ? 'calc(50% - 4px)' : '100%')};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
