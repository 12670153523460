import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import _trim from 'lodash/trim';
import _lowerCase from 'lodash/lowerCase';
import CreatableReactSelect from 'react-select/creatable';

import lensIcon from '../../../../../icons/icon--lens.svg';
import EditableReferenceLabel from '../../../../../EditableReferenceLabel';

const IndicatorsContainer = (props) => {
  return (
    <components.IndicatorsContainer {...props}>
      <img src={lensIcon} />
    </components.IndicatorsContainer>
  );
};

const styles = {
  container: (styles) => ({
    ...styles,
    borderRadius: '8px',
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: '8px',
    borderWidth: '0',
    boxShadow: 'none',
    cursor: 'pointer',
    marginBottom: '5px',
    padding: '2px 2px 2px 5px',
    ':hover': {
      borderWidth: '0',
    },
    ':active': {
      borderWidth: '0',
    },
    ':focus': {
      borderWidth: '0',
    },
    input: {
      margin: '0',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#BDBDBD',
    fontSize: '1rem',
  }),
  valueContainer: (styles) => ({
    ...styles,
  }),
  menuList: (styles, state) => ({
    ...styles,
    maxHeight: state.selectProps.responsive ? '112px' : '217px',
  }),
  menu: (styles) => ({
    ...styles,
    color: 'black',
    borderRadius: '8px',
    backgroundColor: 'rgb(255, 255, 255)',
    overflow: 'hidden',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    borderBottom: 'solid 1px #ddd',
    backgroundColor: isFocused ? '#DADADA' : 'rgba(255, 255, 255, 0)',
    color: '#000',
    ':last-child': {
      borderBottom: 'unset',
    },
    ':active': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    ':hover': {
      backgroundColor: '#DADADA',
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#E0E0E0',
    borderRadius: '200px',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#727278',
    padding: '4px',
    paddingLeft: '16px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#727278',
    padding: '0 8px',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& svg': {
      height: '16px',
      width: '16px',
    },
  }),
  input: (styles) => ({
    ...styles,
    '& input': {
      margin: '0',
      padding: '5px',
    },
    '& input:focus': {
      margin: 'unset',
      boxShadow: 'unset',
      backgroundColor: '#FFF !important',
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    marginRight: '12px',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const filterOption = (candidate, val) => {
  if (val) {
    const label = _trim(_lowerCase(candidate.label));
    const value = _trim(_lowerCase(val));

    // Show always option with name `+ Add Option`
    // At the moment it can be only in case
    // when isMulti equals false
    if (label === 'add option') {
      return true;
    }

    return !!label.match(value) || candidate.data.__isNew__;
  }

  return true;
};

const ReferenceOption = (props) => {
  const { innerProps, innerRef } = props;

  return (
    <div ref={innerRef} {...innerProps}>
      <EditableReferenceLabel html={props.data.label} />
    </div>
  );
};

function Select({ creatable, ...props }) {
  const selectProps = {
    ...props,
    classNamePrefix: 'react-select',
    isClearable: true,
    isSearchable: true,
    maxMenuHeight: 200,
    placeholder: creatable ? 'Select or type other' : 'Select from the list',
    menuPlacement: 'top',
    closeMenuOnSelect: !props.isMulti,
    formatCreateLabel: (inputValue) => <>{`Select Other: "${inputValue}"`}</>,
    components: {
      IndicatorsContainer,
      ...(props.isPreview && { Option: ReferenceOption }),
      ...(props.isPreview && { SingleValue: ReferenceOption }),
    },
    blurInputOnSelect: !props.isMulti,
    filterOption,
    styles,
  };

  if (creatable) {
    return <CreatableReactSelect {...selectProps} />;
  } else {
    return <ReactSelect {...selectProps} />;
  }
}

Select.propTypes = {
  creatable: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMulti: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func.isRequired,
  defaultValue: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default Select;
