import React from 'react';
import PropTypes from 'prop-types';

function ResponseButton(props) {
  const { href, Icon, text, backgroundColor, fontColor, ...restProps } = props;

  return (
    <a
      href={href}
      className="button button--white"
      style={{ color: fontColor, backgroundColor }}
      data-testid="ResponseButton/Anchor"
      {...restProps}
    >
      <span className="button-icon--vertical-flex-wrapper">
        <Icon />
        <span className="button-icon--flex-element">{text}</span>
      </span>
    </a>
  );
}

ResponseButton.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  Icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ResponseButton;
