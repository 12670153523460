import {
  IMAGE_TYPE_ERROR_MESSAGE,
  VIDEO_SIZE_LIMIT,
  VIDEO_TYPE_ERROR_MESSAGE,
  IMAGE_SIZE_LIMIT,
  BACKGROUND_VIDEO_SIZE_ERROR_MESSAGE,
  BACKGROUND_IMAGE_SIZE_ERROR_MESSAGE,
  FILE_SIZE_LIMIT,
  FILE_SIZE_ERROR_MESSAGE,
  UNSUPPORTED_TYPE_ERROR_MESSAGE,
  IMAGE_SIZE_ERROR_MESSAGE,
  ALLOWED_IMAGE_TYPES,
  PREVIEWABLE_VIDEO_TYPES,
  ALL_ALLOWED_FILE_TYPES,
  VIDEO_SIZE_ERROR_MESSAGE,
} from '../../constants';

export const uploadMediaValidator = (file, responder) => {
  if (file.type === '') {
    return {
      code: 'file-invalid-type',
      message: UNSUPPORTED_TYPE_ERROR_MESSAGE,
    };
  }

  if (file.type.includes('video')) {
    if (responder) {
      if (!ALL_ALLOWED_FILE_TYPES.includes(file.type)) {
        return {
          code: 'file-invalid-type',
          message: VIDEO_TYPE_ERROR_MESSAGE,
        };
      }

      if (file.size > VIDEO_SIZE_LIMIT) {
        return {
          code: 'file-too-large',
          message: VIDEO_SIZE_ERROR_MESSAGE,
        };
      }

      if (file.size < 20000) {
        return {
          code: 'file-too-small',
          message: VIDEO_SIZE_ERROR_MESSAGE,
        };
      }
    } else {
      if (file.size > VIDEO_SIZE_LIMIT) {
        return {
          code: 'file-too-large',
          message: BACKGROUND_VIDEO_SIZE_ERROR_MESSAGE,
        };
      }

      if (file.size < 10000) {
        return {
          code: 'file-too-small',
          message: BACKGROUND_VIDEO_SIZE_ERROR_MESSAGE,
        };
      }

      if (!PREVIEWABLE_VIDEO_TYPES.includes(file.type)) {
        return {
          code: 'file-invalid-type',
          message: VIDEO_TYPE_ERROR_MESSAGE,
        };
      }
    }
  } else if (file.type.includes('image')) {
    if (file.size > IMAGE_SIZE_LIMIT) {
      return {
        code: 'file-too-large',
        message: responder
          ? IMAGE_SIZE_ERROR_MESSAGE
          : BACKGROUND_IMAGE_SIZE_ERROR_MESSAGE,
      };
    }

    if (file.size < 100) {
      return {
        code: 'file-too-small',
        message: BACKGROUND_IMAGE_SIZE_ERROR_MESSAGE,
      };
    }

    if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
      return {
        code: 'file-invalid-type',
        message: IMAGE_TYPE_ERROR_MESSAGE,
      };
    }
  } else if (ALL_ALLOWED_FILE_TYPES.includes(file.type)) {
    if (file.size > FILE_SIZE_LIMIT) {
      return {
        code: 'file-too-large',
        message: FILE_SIZE_ERROR_MESSAGE,
      };
    }
  } else {
    return {
      code: 'file-invalid-type',
      message: UNSUPPORTED_TYPE_ERROR_MESSAGE,
    };
  }

  return null;
};
