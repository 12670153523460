import React from 'react';
import PropTypes from 'prop-types';
import { Uploader } from '../../../../uploader';
import UploadProgress from '../UploadProgress';

function UploadingState({
  file,
  hidden,
  uploadUrl,
  refreshState,
  uploadCreateCallback,
}) {
  if (hidden) {
    return null;
  }

  const progressBarRef = React.useRef(null);
  const progressPercentageRef = React.useRef(null);
  const activeUpload = React.useRef(null);

  React.useEffect(() => {
    handleUploadMedia();
  }, []);

  const setActiveUploadRequest = (request) => {
    activeUpload.current = request;
  };

  const cancelUpload = () => {
    // Interval is needed in case of slow internet
    // eslint-disable-next-line prefer-const
    let intervalId;
    const stopUploading = () => {
      if (activeUpload.current) {
        activeUpload.current.abort();
        clearInterval(intervalId);
      }
    };
    intervalId = setInterval(stopUploading, 500);

    refreshState();
  };

  const handleUploadMedia = () => {
    const upload = new Uploader(
      file,
      uploadUrl,
      progressPercentageRef.current,
      progressBarRef.current,
      setActiveUploadRequest,
    );

    upload.create(uploadCreateCallback);
  };

  return (
    <UploadProgress
      percentageRef={progressPercentageRef}
      progressBarRef={progressBarRef}
      cancelUpload={cancelUpload}
    />
  );
}

UploadingState.propTypes = {
  file: PropTypes.object,
  hidden: PropTypes.bool,
  uploadCreateCallback: PropTypes.func.isRequired,
  uploadUrl: PropTypes.string,
  refreshState: PropTypes.func,
};

export default UploadingState;
