import React from 'react';
import LazyLoad from 'vanilla-lazyload';

export default function BackgroundVideo({
  placeholderImageUrl,
  backgroundMediaOpacity,
  videoSources,
}) {
  React.useEffect(() => {
    if (!document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad();
    }

    document.lazyLoadInstance.update();
  }, []);

  return (
    <video
      className="lazy"
      data-poster={placeholderImageUrl}
      data-testid="BackgroundOverlay/Video"
      muted
      loop
      autoPlay
      playsInline
      controls={false}
      style={{ opacity: backgroundMediaOpacity }}
    >
      {videoSources.map((url) => (
        <source key={url} data-src={url} />
      ))}
    </video>
  );
}
