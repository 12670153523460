import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import { DataFieldWrapper } from './components/DataFieldWrapper';
import { Container, DataFieldContainer } from './style';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isHalfWidth, buildCssStyle } from './utils';
import BlockLabel from '../../shared/BlockLabel';

function DataFieldsSelector({
  fields,
  responses,
  currency,
  errors,
  disabledFields,
  isPreview,
  askLabel,
}) {
  const theme = createTheme({
    typography: {
      fontFamily: 'inherit',
    },
    overrides: {
      MuiInputLabel: {
        root: {
          letterSpacing: 'normal',
        },
      },
    },
  });
  const inputName = (index, attrName) => {
    return `data_response[data_field_responses_attributes][${index}][${attrName}]`;
  };

  const errorKey = (index) => `data_field_responses[${index}].value`;

  const parseErrors = (index) => {
    if (_isEmpty(errors)) {
      return null;
    } else {
      const errorMessages = errors[errorKey(index)];

      return errorMessages ? errorMessages[0] : null;
    }
  };

  const getResponse = (id) => {
    if (responses.length > 0) {
      return responses.find((response) => response.attributes.dataFieldId == id);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BlockLabel askLabel={askLabel} isPreview={isPreview} />
      <Container>
        {fields.map((item, index) => {
          const field = item.attributes || item;
          const response = getResponse(field.id);

          return (
            <DataFieldContainer
              halfWidth={isHalfWidth(
                item,
                fields[index - 1],
                fields[index + 1],
              )}
              key={index}
              data-testid={`DataFieldsSelector/Field_${field.id}`}
              data-testtype={field.type}
              data-css-selector="data field"
              hidden={field.isHidden}
            >
              <DataFieldWrapper
                type={field.type}
                name={inputName(index, 'value')}
                optionName={inputName(index, 'mc_option_id')}
                required={field.required}
                richText={field.richText}
                multipleChoiceOptions={field.multipleChoiceOptions}
                errorKey={errorKey(index)}
                currency={currency}
                checkboxHidden={field.checkboxHidden}
                fieldCurrency={field.currency}
                disabled={disabledFields}
                defaultValue={response ? response.attributes.value : field.defaultValue}
                error={parseErrors(index)}
                isPreview={isPreview}
                cssStyle={buildCssStyle(field.type, field.cssStyle)}
              />

              {response && response.id && (
                <input
                  type="hidden"
                  name={inputName(index, 'id')}
                  value={response.id}
                />
              )}
              <input
                type="hidden"
                name={inputName(index, 'data_field_id')}
                value={field.id}
              />
              <input
                type="hidden"
                name={inputName(index, 'hidden')}
                value={field.isHidden}
              />
            </DataFieldContainer>
          );
        })}
      </Container>
    </ThemeProvider>
  );
}

DataFieldsSelector.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.string.isRequired,
  errors: PropTypes.object,
  disabledFields: PropTypes.bool,
};

export default DataFieldsSelector;
