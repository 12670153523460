import { useState, useEffect, useContext } from 'react';
import ReferencesContext, { ADD_REFERENCE } from '../../contexts/ReferencesContext';

function useReferenceName(reference) {
  const [{ references }, dispatch] = useContext(ReferencesContext);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const parsedText = reference?.split(/({{.*?}})/g).map((text) => {
      if (!text.match(/{{(?!#|\/).*}}/)) {
        return text;
      }

      const ref = references[text];

      if (!ref) {
        dispatch({
          type: ADD_REFERENCE,
          payload: {
            reference: text,
          },
        });
      }

      return ref?.name;
    });

    setContent(parsedText);
  }, [reference, references]);

  return content?.join('');
}

export default useReferenceName;
