import styled from 'styled-components';

export const Logo = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 9px;
  background-image: url(${(props) => props.logoUrl});
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  @media (min-width: 375px) {
    width: 32px;
    height: 32px;
  }

  @media (min-width: 768px) {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  @media (min-width: 1024px) {
    width: 54px;
    height: 54px;
  }
`;

export const BrandingName = styled.div`
  flex: 1;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 500;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 375px) {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

export const Anchor = styled.a`
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
  color: #fff;
  text-decoration: none;
  margin-right: 100px;
  transform-origin: left;
  transition: transform 0.2s;

  &:hover {
    color: #fff;
    transform: scale(1.04);
  }
`;
