import { setClassName } from '../../utils';

export const buildParams = (askerSide, signedId) => {
  return askerSide
    ? { ask: { background_video_signed_id: signedId } }
    : { ugc_media_response: { video_signed_id: signedId } };
};

export const setButtonsWrapperClassName = (askerSide) => {
  return askerSide
    ? 'media-background__buttons-container'
    : 'ha-form__record-button-wrapper';
};

export const setHelpTextClassName = (askerSide) => {
  return askerSide ? 'media-background__controls-title' : 'ha-form__help-text';
};

export const setCountdownClassName = (askerSide) => {
  return askerSide
    ? 'media-background__controls-title'
    : 'ha-form__record_countdown--centered';
};

export const setMediaWrapperClassNames = (askerSide, backgroundIsColor) => {
  return askerSide
    ? 'media-background__media-wrapper'
    : `upload-record-wrapper ${setClassName('upload-record-wrapper--color-background', backgroundIsColor)}`;
};


export const setVideoTagClassNames = (askerSide, backgroundLayout) => {
  let baseClassName;

  if (askerSide) {
    baseClassName = 'media-background__video';
  } else {
    baseClassName = 'ha-form__background-video';
  }

  return `${baseClassName} ${setClassName(
    'fit-to-size',
    backgroundLayout === 'fit_to_size',
  )}`;
};
