import { Controller } from 'stimulus';
import { hideElement, showElement, isHiddenElement } from '../utils';

export default class extends Controller {
  static targets = [
    'trigger',
    'window',
  ];


  toggle(e) {
    const popup = this._findPopup(e.currentTarget.dataset.reference);

    this._toggleWindow(popup);
  }

  _toggleWindow(popup) {
    if (isHiddenElement(popup)) {
      showElement(popup);
      this._setBodyOverflow('hidden');
    } else {
      hideElement(popup);
      this._setBodyOverflow('auto');
    }
  }

  _findPopup(reference) {
    const finder = (element) => element.dataset.reference === reference;
    return this.windowTargets.find(finder);
  }

  _setBodyOverflow(value) {
    document.body.style.overflow = value;
  }
}
