import React from 'react';
import LazyLoad from 'vanilla-lazyload';

export default function LazyImage(props) {
  React.useEffect(() => {
    if (!document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad();
    }

    document.lazyLoadInstance.update();
  }, []);

  return <img className="lazy" {...props} />;
}
