import React from 'react';
import styled from 'styled-components';

import MetricsBottomBar, {
  propTypes as MetricsPropTypes,
  defaultProps as DefaultMetricsPropTypes,
} from '../../ask/MetricsBottomBar';

const Container = styled.div`
  width: 100%;
  height: fit-content;
  z-index: 3;
`;

function BottomBar({ askMetric, isPreview }) {
  return (
    <Container>
      <MetricsBottomBar {...askMetric} isPreview={isPreview} />
    </Container>
  );
}

BottomBar.propTypes = MetricsPropTypes;
BottomBar.defaultProps = DefaultMetricsPropTypes;

export default BottomBar;
