import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ControlBar, BigPlayButton } from 'video-react';
import { setValueToStore } from '../../../../sharedStore';
import {
  ClickableBackground,
  StyledPlayer,
  PlayButton,
  ControlButton,
  ControlButtonsContainer,
} from './style';

import muteIcon from '../../../icons/icon--mute.svg';
import volumeIcon from '../../../icons/icon--volume.svg';
import playButtonIcon from '../../../icons/icon--play-button.svg';
import replayIcon from '../../../icons/icon--replay.svg';
import Shortcut from 'video-react/lib/components/Shortcut';
import LoadingSpinner from './components/LoadingSpinner';
import LazyLoad from 'vanilla-lazyload';

function VideoPlayer({
  sources,
  hidePlayButton,
  backgroundLayout,
  withShareIcon,
  onVideoPlaying,
  onPlayButtonClick,
  placeholderImageUrl,
}) {
  const [muted, setMuted] = useState(false);
  const [loop, setLoop] = useState(true);
  const [controlsAreHidden, setControlsAreHidden] = useState(false);
  const [isPlaying, setIsPlaying] = useState(hidePlayButton);
  const [withProgressBar, setWithProgressBar] = useState(false);
  // readyState possible values: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
  const [readyState, setReadyState] = React.useState(0);

  const player = React.useRef(null);

  // handle postMessage from the widget
  // and pause video if widget is closed
  const handlePostMessage = ({ data }) => {
    const isClosed = data.type === 'ha-widget' && !data.isOpen;

    if (isClosed) {
      if (hidePlayButton) {
        setMuted(true);
      } else {
        player.current.pause();
      }
    }
  };

  const handlePlayerStateChange = (state) => {
    // This condition prevents bug when video loops
    if (state.readyState !== 1) {
      setReadyState(state.readyState);
    }
  };

  useEffect(() => {
    if (hidePlayButton) {
      setIsPlaying(true);
    }
  }, [hidePlayButton]);

  useEffect(() => {
    setMuted(true);
    const progressBar = document.querySelector(
      '[data-react-class="ProgressBar"]',
    );
    if (progressBar && progressBar.children.length) {
      setWithProgressBar(true);
    }

    if (player.current) {
      // we can't use autoplay with turbo-rails https://github.com/hotwired/turbo-rails/issues/147
      player.current.video.video.addEventListener('canplay', () => {
        player.current.play();
      });
      // prevent full screen video
      player.current.actions.toggleFullscreen = () => {};
      player.current.video.video.classList.add('lazy');
      player.current.video.video.dataset.poster = placeholderImageUrl;
      player.current.subscribeToStateChange(handlePlayerStateChange);
    }

    if (sources.length < 1) {
      setControlsAreHidden(true);
    }

    if (!document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad();
    }

    document.lazyLoadInstance.update();
    // Listeners
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  useEffect(() => {
    if (onVideoPlaying) {
      onVideoPlaying(isPlaying);
    }
  }, [isPlaying, onVideoPlaying]);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const restart = () => {
    if (player.current) {
      player.current.seek(0);
      player.current.play();
    }
  };

  const play = () => {
    if (loop) {
      player.current.seek(0);
    }

    if (onPlayButtonClick) {
      onPlayButtonClick();
    }

    setMuted(false);
    setLoop(false);
    setIsPlaying(true);

    player.current.play();

    setValueToStore('videoStartedPlaying', true);
    setValueToStore('videoIsPlaying', true);
  };

  const handleOnPause = () => {
    setIsPlaying(false);
    setValueToStore('videoIsPlaying', false);
  };

  const videoEnded = () => {
    setIsPlaying(false);

    setValueToStore('videoEndedPlaying', true);
    setValueToStore('videoIsPlaying', false);
  };

  const handleBackgroundClick = () => {
    if (hidePlayButton) {
      setMuted(!muted);
      return;
    }
    if (isPlaying) {
      player.current.actions.pause();
    } else {
      play();
    }
  };

  return (
    <>
      <ClickableBackground
        onClick={handleBackgroundClick}
        isHidden={controlsAreHidden}
        tabIndex="-1"
        data-testid="VideoPlayer/ClickableBackground"
      />
      <StyledPlayer
        ref={player}
        playsInline
        loop={loop}
        muted={muted}
        fluid={false}
        width=""
        height=""
        onEnded={videoEnded}
        onPause={handleOnPause}
        layout={backgroundLayout}
        data-testid="VideoPlayer/Player"
      >
        {sources.map((source) => (
          <source data-src={source} key={source} />
        ))}
        <LoadingSpinner
          playButtonIsHidden={hidePlayButton}
          readyState={readyState}
        />
        {!controlsAreHidden && readyState > 1 && (
          <>
            {isPlaying || hidePlayButton ? (
              <ControlButtonsContainer
                withProgressBar={withProgressBar}
                withShareIcon={withShareIcon}
                data-testid="VideoPlayer/ControlButtonsContainer"
              >
                <ControlButton
                  onClick={toggleMute}
                  data-testid="VideoPlayer/MuteButton"
                >
                  {muted ? <img src={muteIcon} /> : <img src={volumeIcon} />}
                </ControlButton>
                <ControlButton
                  onClick={restart}
                  data-testid="VideoPlayer/ReplayButton"
                >
                  <img src={replayIcon} />
                </ControlButton>
              </ControlButtonsContainer>
            ) : (
              !hidePlayButton && readyState > 2 && (
                <PlayButton onClick={play} data-testid="VideoPlayer/PlayButton">
                  <img src={playButtonIcon} />
                </PlayButton>
              )
            )}
          </>
        )}

        {/*
            Disable default BigPlayButton.
            We use our own PlayButton instead
          */}
        <BigPlayButton disabled />
        {/*
            We don't need ControlBar
          */}
        <ControlBar disableCompletely={true} />
        <Shortcut clickable={false} />
      </StyledPlayer>
    </>
  );
}

VideoPlayer.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  hidePlayButton: PropTypes.bool,
  backgroundLayout: PropTypes.string,
  onVideoPlaying: PropTypes.func,
};

export default VideoPlayer;
