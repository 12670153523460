import { Controller } from 'stimulus';

import { hideElement, showElement, isHiddenElement } from '../utils';

import { subscribeTo } from '../sharedStore';

const TITLE_HIDE_CLASS = 'hide-title-on-mobile';
export default class extends Controller {
  static targets = [
    'playButton',
    'playButtonContainer',
    'responseFormContainer',
    'responseAskTitle',
    'cta',
    'recordVideoControls',
  ];

  connect() {
    if (
      this.hasPlayButtonContainerTarget &&
      !isHiddenElement(this.playButtonContainerTarget)
    ) {
      this._hideRecordControls();
    }

    this._setInitialState();
    subscribeTo('videoStartedPlaying', this._showButtons.bind(this));
    subscribeTo('videoIsPlaying', this._toggleAskTitle.bind(this));
  }

  _showButtons() {
    if (this.hasResponseFormContainerTarget) {
      this.responseFormContainerTarget.classList.remove('hide-on-mobile');
    }

    if (this.hasCtaTarget) {
      this.ctaTarget.classList.remove('hide-on-mobile');
    }

    this._showRecordControls();
  }

  _toggleAskTitle(value) {
    if (this.hasResponseAskTitleTarget) {
      if (value) {
        this.responseAskTitleTarget.classList.add(TITLE_HIDE_CLASS);
      } else {
        this.responseAskTitleTarget.classList.remove(TITLE_HIDE_CLASS);
      }
    }
  }

  _setInitialState() {
    if (this._showActionButtons()) {
      if (this.hasResponseFormContainerTarget) {
        this.responseFormContainerTarget.classList.remove('hide-on-mobile');
      }

      if (this.hasCtaTarget) {
        this.ctaTarget.classList.remove('hide-on-mobile');
      }

      this._showRecordControls();
    }
  }

  _showActionButtons() {
    const startingScreen = this.data.get('starting-screen');

    if (startingScreen === 'play_button_first') {
      return false;
    }

    return true;
  }

  _hideRecordControls = () => {
    if (this.hasRecordVideoControlsTarget) {
      hideElement(this.recordVideoControlsTarget);
    }
  };

  _showRecordControls = () => {
    if (this.hasRecordVideoControlsTarget) {
      showElement(this.recordVideoControlsTarget);
    }
  };
}
