import React from 'react';
import MUICheckbox from '@mui/material/Checkbox';

export default function Checkbox({ checked, color }) {
  return (
    <MUICheckbox
      sx={{
        position: 'absolute',
        py: 0,
        left: 6,
        top: '50%',
        transform: 'translateY(-50%)',
        '&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
          color: color || 'var(--primary-color)',
          opacity: 1,
        },
        '&.MuiButtonBase-root.MuiCheckbox-root .MuiSvgIcon-root': {
          color: color || 'rgba(0, 0, 0, 0.2)',
          opacity: color ? 0.2 : 1,
        },
      }}
      checked={checked}
      disableRipple
    />
  );
}
