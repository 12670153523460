import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '../TextField';
import { CURRENCY_SIGN_MAP } from '../../../constants';

export default function MoneyTextField({ name, ...props }) {
  const { defaultValue, isPreview, fieldCurrency } = props;
  const number = defaultValue && defaultValue.replace(/[^0-9.-]+/g, '');

  const [value, setValue] = useState(isPreview ? defaultValue : number);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <TextField
        {...props}
        defaultValue={value}
        onChange={handleChange}
        type={isPreview ? 'text' : 'number'}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{
                '&.MuiInputAdornment-positionEnd': {
                  fontSize: 14,
                  textTransform: 'uppercase',
                },
              }}
              position="end"
            >
              <span>{CURRENCY_SIGN_MAP[fieldCurrency]} {fieldCurrency}</span>
            </InputAdornment>
          ),
        }}
      />
      {value && (
        <input
          type="hidden"
          name={name}
          value={`${value} ${props.fieldCurrency.toUpperCase()}`}
        />
      )}
    </>
  );
}
