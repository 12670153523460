import React from 'react';
import { setClassName } from '../../../../../utils';

export function UploadedImage({ src, backgroundLayout }) {
  return (
    <img
      data-testid="UgcUploadResponse/Image"
      src={src}
      className={`upload-image ${setClassName(
        'fit-to-size',
        backgroundLayout === 'fit_to_size',
      )}`}
    />
  );
}
