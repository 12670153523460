import styled from 'styled-components';
import { darken } from 'polished';

//
// Single and Multi OptionButtons
//
const OptionButtonTemplate = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.125rem;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin: 0.5rem 0 0;
  padding: 0.5rem 3rem;
  border-radius: 8px;
  border: 0px solid transparent;
  font-size: 0.875rem;
  background-color: ${(props) =>
    props.checked ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)'};
  color: ${(props) => props.color || '#35353f'};
  white-space: pre-wrap;
  line-height: 1.2;
  cursor: pointer;
  overflow: hidden;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  & p {
    width: 100%;
    margin: 0;
  }

  & .preview_reference {
    background-color: rgba(114, 114, 120, 0.2);
    border-radius: 4px;
    color: inherit;
    padding: 2px 4px;
    margin: 4px 2px;
    outline: none;
  }

  &:hover {
    background-color: ${(props) =>
      props.checked ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)'};
    color: ${(props) => props.color || '#35353f'};
  }

  &:active {
    background-color: #fff;
    color: ${(props) => props.color || '#35353f'};
  }

  &:focus {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #fff;
      color: ${(props) => props.color || '#35353f'};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 1;

    &:hover {
      background-color: #fff;
    }
  }
`;

export const MultiSelectOptionButton = styled(OptionButtonTemplate)`
  display: flex;
  align-items: center;
  padding-right: 1.5rem;

  & > div {
    width: 100%;
  }
`;

export const SingleSelectOptionButton = styled(OptionButtonTemplate)`
  border-radius: 2rem;
  background-color: ${(props) =>
    props.selected ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)'};
`;

//
// Single and Multi Select OtherButtons
//
export const OtherButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
`;

export const ActionButton = styled.button`
  position: absolute;
  width: auto;
  font-size: 0.875rem;
  border-radius: 8px;
  right: 1rem;
  border: 0px solid transparent;
  height: 3.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  color: #0078f5;

  &:hover {
    background-color: #fff;
    color: ${darken(0.1, '#0078F5')};
  }

  &:focus {
    outline: none;
  }
`;

export const ActionButtonRounded = styled(ActionButton)`
  border-radius: 2rem;
`;

export const InnerContentContainerForButton = styled.span`
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CancelButton = styled(ActionButton)`
  color: #727278;

  &:hover {
    background-color: #fff;
    color: ${darken(0.1, '#727278')};
  }
`;

export const EditButton = styled.a`
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 0.875rem;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #727278;
  height: 3.125rem;

  &:hover {
    color: ${darken(0.1, '#727278')};
  }
`;

// &&& - here for overriding global input styles
// It will add dynamic class name to the component three times
// .ifhhMW.ifhhMW.ifhhMW {...} instead of .ifhhMW {...}
export const OtherButtonInput = styled.input`
  &&& {
    border: 0px solid blue;
    background-color: #fff;
    text-align: left;
    border-radius: 8px;
    color: #000;
    padding-left: 3rem;
    padding-right: 4rem;
    height: 3.125rem;
    box-shadow: none;
    margin: 0.5rem 0 0 0;
  }
`;

export const OtherButtonInputRounded = styled(OtherButtonInput)`
  &&& {
    border-radius: 2rem;
  }
`;

export const ErrorMessage = styled.span`
  border-radius: 5px;
  text-align: center;
  background-color: #e74c0a;
  font-size: 12px;
  padding: 5px 12px;
  position: absolute;
  right: 0;
  z-index: 5;
  margin-top: -10px;
`;
