import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;

  @media (min-width: 768px) {
    top: 16px;
  }

  @media (min-width: 961px) {
    top: ${(props) => (props.withProgressBar ? '64px' : '18px')};
    right: 24px;
  }
`;

export const ShareIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${(props) => (props.isOpen ? '#333' : 'transparent')};
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 961px) {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;
