import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
`;

export const SubmitButton = styled.button`
  font-size: 12px;
  color: #bdbdbd;
  text-decoration: none;
  transition: color 0.1s;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1.4;

  &:hover {
    color: #fff;
    background: none;

    &:disabled {
      background: none;
    }
  }

  &:after {
    content: '→';
    margin-left: 4px;
  }
`;
