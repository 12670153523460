import { Controller } from 'stimulus';
import * as clipboard from 'clipboard-polyfill';
import _find from 'lodash/find';

import { hideElement, showElement  } from '../utils';

const ACTIVE_SWITCHER_CLASS = 'popup__button--active';
const HEADER_SIZE = '60px';
const NOTICE_SHOW_TIME = 3000;

export default class extends Controller {
  static targets = [
    'switcher',
    'window',
    'area',
    'copyNotice',
  ]

  connect() {
    this._hideAll();
    this._showFirst();
  }

  _hideAll() {
    this.windowTargets.forEach(hideElement);
    this.switcherTargets.forEach((element) => element.classList.remove(ACTIVE_SWITCHER_CLASS));
  }

  _showFirst() {
    const panel = this.windowTargets[0];
    const button = this._findByReference(this.switcherTargets, panel.dataset.reference);

    button.classList.add(ACTIVE_SWITCHER_CLASS);
    showElement(panel);
  }

  _findByReference(elements, reference) {
    return elements.find((e) => e.dataset.reference === reference);
  }

  _activateByReference(reference) {
    const panel = this._findByReference(this.windowTargets, reference);
    const button = this._findByReference(this.switcherTargets, reference);

    button.classList.add(ACTIVE_SWITCHER_CLASS);
    showElement(panel);
  }

  _setNoticeTop({ target, scrollable }) {
    return () => {
      if (scrollable.scrollTop > 60) {
        target.style.top = `${scrollable.scrollTop}px`;
      } else {
        target.style.top = HEADER_SIZE;
      }
    };
  };

  /**
   * Exposed controller functions
   */
  changeTab(e) {
    this._hideAll();
    this._activateByReference(e.currentTarget.dataset.reference);
  }

  copy(e) {
    const targetName = e.currentTarget.dataset.widgetTabsTarget;
    const element = this.areaTargets.find((e) => e.dataset.widgetTabsTarget === targetName);

    clipboard.writeText(element.value);
    this.showCopyNotice();
  }

  showCopyNotice() {
    const target = this.copyNoticeTarget;
    const scrollable = _find(
      document.getElementsByClassName('popup'),
      (e) => !e.classList.contains('hidden')
    );
    const setNoticePosition = this._setNoticeTop({ target, scrollable });

    setNoticePosition();
    showElement(target);
    scrollable.addEventListener(
      'scroll',
      setNoticePosition
    );

    if (this.timeout) {
      clearTimeout(this.timeout);
    };

    this.timeout = setTimeout(
      () => {
        hideElement(this.copyNoticeTarget);
        scrollable.removeEventListener('scroll', setNoticePosition);
      },
      NOTICE_SHOW_TIME,
    );
  }
}
