import React from 'react';
import PropTypes from 'prop-types';
import VideoBackground from '../../../videoplayer/VideoBackground';
import { UploadedImage } from '../UploadedImage';
import { UploadedFile } from '../UploadedFile';
import {
  PREVIEWABLE_VIDEO_TYPES,
  ALLOWED_IMAGE_TYPES,
} from '../../../../../constants';

function MediaPreview({ files, backgroundLayout, backgroundIsColor }) {
  return files.map((file) => {
    if (ALLOWED_IMAGE_TYPES.includes(file.type)) {
      return (
        <UploadedImage
          key={file.name}
          src={file.preview}
          backgroundLayout={backgroundLayout}
        />
      );
    } else if (PREVIEWABLE_VIDEO_TYPES.includes(file.type)) {
      return (
        <div key={file.name} data-testid="VideoBackground/Upload">
          <VideoBackground
            sources={[file.preview]}
            backgroundLayout={backgroundLayout}
            backgroundIsColor={backgroundIsColor}
          />
        </div>
      );
    } else {
      return <UploadedFile key={file.name} name={file.name} />;
    }
  });
}

MediaPreview.propTypes = {
  files: PropTypes.array,
  backgroundLayout: PropTypes.string,
};

export default MediaPreview;
