import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import _uniqBy from 'lodash/uniqBy';
import _compact from 'lodash/compact';
import _isArray from 'lodash/isArray';
import wretch from 'wretch';

import Select from './components/Select';
import { Input } from '../../../../inputs/Input';
import { OtherInputContainer, ActionButton } from './style';
import CtaButton from '../../../../shared/CtaButton';

const prepareDefaultOptions = (options, defaultValues) =>
  _compact(_uniqBy([...options, ...defaultValues], 'value'));

const prepareDefaultValues = (currentValues) =>
  currentValues.map(([id, name]) => ({ value: id, label: name }));

const prepareErrorMessage = (message) => {
  const errors = JSON.parse(message).errors;
  const responsesErrors = errors.multiple_choice_option_responses;

  if (_isArray(responsesErrors)) {
    return responsesErrors[0];
  } else {
    return responsesErrors.multiple_choice_option_name[0];
  }
};

function OtherOptionInput({ onClick, onChange, onKeyPress }) {
  return (
    <OtherInputContainer>
      <Input
        onChange={onChange}
        onKeyPress={onKeyPress}
        autoFocus
        noMargin
        placeholder="Add your choice..."
        data-testid="otherButton/input"
      />
      <ActionButton onClick={onClick} data-testid="otherButton/cancelButton">
        Cancel
      </ActionButton>
    </OtherInputContainer>
  );
}

function SelectBoxSelector({
  options: basicOptions,
  currentValues,
  otherOptionId,
  withOtherOption,
  disabled,
  responsive,
  responseId,
  isMulti,
  setErrorMessage,
  ctaButtonContent,
  ctaButtonStyle,
  isPreview,
}) {
  // basicOptions equals to: [[1, "option 1"], [2, "option 2"]]
  // defaultOptions should be: [ { value: "1", label: "option 1" }, { value: "2", label: "option 2" }]
  const defaultOptions = basicOptions.map(([id, name]) => ({
    value: id,
    label: name,
  }));

  if (!isMulti && withOtherOption) {
    defaultOptions.push({ value: otherOptionId, label: '+ Add Option' });
  }

  const defaultValues = prepareDefaultValues(currentValues);
  const [options, setOptions] = useState(
    prepareDefaultOptions(defaultOptions, defaultValues),
  );
  const [selectedOptions, setSelectedOptions] = useState(defaultValues);
  const [otherInputIsActive, setOtherInputIsActive] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState('');
  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(false);

  useEffect(() => {
    setOptions(prepareDefaultOptions(defaultOptions, defaultValues));
  }, [basicOptions, currentValues]);

  const handleCreateOption = (option) => {
    const formedOption = { value: otherOptionId, label: option };
    const nextOptions = options.filter(({ value }) => value !== otherOptionId);
    const nextSelectedOptions = selectedOptions.filter(
      ({ value }) => value !== otherOptionId,
    );

    setErrorMessage(null);
    setOptions([...nextOptions, formedOption]);
    setSelectedOptions([...nextSelectedOptions, formedOption]);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === otherOptionId) {
      setOtherInputIsActive(true);
      setSelectedOptions([]);
    } else {
      setOtherInputIsActive(false);
      setSelectedOptions(selectedOption || []);
    }

    setErrorMessage(null);
  };

  const onCancelClick = (e) => {
    e.preventDefault();

    setDefaultMenuIsOpen(true);
    setOtherInputIsActive(false);
    setSelectedOptions([]);
    setErrorMessage(null);
  };

  const onOtherInputChange = (e) => {
    setOtherInputValue(e.target.value);
    setErrorMessage(null);
  };

  const onOtherInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let options;

    if (otherInputIsActive) {
      options = [{ label: otherInputValue, value: otherOptionId }];
    } else {
      options = _isArray(selectedOptions) ? selectedOptions : [selectedOptions];
    }

    const params = {
      response: {
        multiple_choice_option_responses: options.map(({ value, label }) => {
          return {
            multiple_choice_option_name: label,
            multiple_choice_option_id: value,
          };
        }),
      },
    };

    wretch(`/api/v1/responses/${responseId}/multiple_choices`)
      .content('application/json')
      .put(params)
      .json((json) => {
        window.location = json.redirect_url;
      })
      .catch((error) => {
        const message = prepareErrorMessage(error.message);

        setErrorMessage(message);
      });
  };

  const customProps = {
    value: selectedOptions,
    onChange: handleChange,
    onCreateOption: handleCreateOption,
    defaultValue: defaultValues,
    options,
    creatable: withOtherOption && isMulti,
    responsive,
    isMulti,
    defaultMenuIsOpen,
    autoFocus: defaultMenuIsOpen,
    isPreview,
  };

  return (
    <>
      {!otherInputIsActive && <Select {...customProps} />}
      {otherInputIsActive && (
        <OtherOptionInput
          onChange={onOtherInputChange}
          onClick={onCancelClick}
          onKeyPress={onOtherInputKeyPress}
        />
      )}
      <CtaButton
        onClick={handleSubmit}
        disabled={disabled}
        data-testid="SelectBoxSelector/submit"
        ctaButtonStyle={ctaButtonStyle}
        ctaButtonContent={ctaButtonContent}
        isPreview={isPreview}
      />
    </>
  );
}

SelectBoxSelector.propTypes = {
  currentValues: PropTypes.array.isRequired,
  otherOptionId: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  withOtherOption: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  responsive: PropTypes.bool,
  responseId: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  ctaButtonContent: PropTypes.string.isRequired,
};

export default SelectBoxSelector;
