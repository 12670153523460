import React from 'react';
import VideoBackground from '../../../videoplayer/VideoBackground';
import { UploadedImage } from '../UploadedImage';
import { UploadedFile } from '../UploadedFile';

function ResponsePreview({
  attachment,
  backgroundLayout,
  backgroundIsColor,
}) {
  if (!attachment) {
    return null;
  }

  const { type, name, file_url } = attachment;

  if (type === 'image') {
    return (
      <UploadedImage
        key={name}
        src={file_url}
        backgroundLayout={backgroundLayout}
      />
    );
  } else if (type === 'video') {
    return (
      <div key={name} data-testid="VideoBackground/Upload">
        <VideoBackground
          sources={[file_url]}
          backgroundLayout={backgroundLayout}
          backgroundIsColor={backgroundIsColor}
        />
      </div>
    );
  } else {
    return <UploadedFile key={name} name={name} />;
  }
}

export default ResponsePreview;
