import {Controller} from 'stimulus';
import {showElement, hideElement} from '../utils';

export default class extends Controller {
  static targets = [
    'source',
    'form',
    'colorBox',
    'codeBlock',
  ];

  connect() {
    this.currentCodeBlock = 'basic';
    this._showCodeBlock();
    this._bindFormDataToTemplate();
    this.setColorBox();
  }

  _showCodeBlock = () => {
    this.codeBlockTargets.forEach((codeBlock) => {
      if (codeBlock.dataset.blockName === this.currentCodeBlock) {
        showElement(codeBlock);
      } else {
        hideElement(codeBlock);
      }
    });
  }

  _createTemplate = ({
    askUrl,
    dataUrl,
    widgetUrl,
    color,
    title,
    shape,
    position = 'right',
  }) => {
    const header = `<!-- Widget Code -->
  <script src="${widgetUrl}" async></script>
<!-- Widget Code -->`;
    const body = `<script class="ha-widget-config" type="application/json">
  {
    "url": "${askUrl}",
    "dataUrl": "${dataUrl}",
    "title": "${title}",
    "shape": "${shape}",
    "widget": true,
    "color": "${color}",
    "position": "${position}"
  }
</script>
`;

    return { header, body };
  };

  _bindFormDataToTemplate = () => {
    const { askUrl, dataUrl, widgetUrl } = this.sourceTarget.dataset;
    const form = this.formTarget;
    const title = form.title.value;
    const color = form.color.value;
    const position = form.position.value;
    const shape = form.shape.value;

    const { header, body } = this._createTemplate({
      askUrl,
      dataUrl,
      widgetUrl,
      color,
      title,
      position,
      shape,
    });

    this.sourceTargets.forEach((target) => {
      switch (target.dataset.widgetTabsTarget) {
        case 'widget-head':
          target.innerHTML = header;
          break;
        case 'widget-body':
          target.innerHTML = body;
          break;
        case 'widget':
          target.innerHTML = [header, body].join('\n\n');
          break;
      };
    });
  };

  setColorBox = (e) => {
    let color;

    if (e) {
      color = e.currentTarget.value;
    } else {
      color = this.formTarget.color.value;
    }

    if (this.color === color) {
      return;
    }

    this.color = color;
    this.colorBoxTarget.style.backgroundColor = color;
  }

  changeWidgetType = (e) => {
    const nextWidgetType = e.currentTarget.value;
    this.currentCodeBlock = nextWidgetType;

    this._showCodeBlock();
  }

  onFormUpdate = this._bindFormDataToTemplate;
}
