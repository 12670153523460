export const initialStyles = {
  width: '100%',
  maxWidth: '100%',
  fontSize: 18,
  lineHeight: '22px',
  textAlign: 'center',
  fontWeight: 500,
  cursor: 'pointer',
  letterSpacing: '-0.5px',
  transition: 'transform 0.1s',
  color: 'rgba(255, 255, 255, 0.8)',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderColor: 'rgba(255, 255, 255, 0.4)',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '8px',
  boxShadow: 'none',
  marginTop: '0px',
  marginRight: '0px',
  marginBottom: '0px',
  marginLeft: '0px',
  paddingTop: '12px',
  paddingRight: '0px',
  paddingBottom: '12px',
  paddingLeft: '0px',
};

export const checkedStyles = {
  borderStyle: 'solid',
  borderColor: 'rgba(255, 255, 255, 0.4)',
  backgroundColor: '#fff',
  color: '#000',
};

