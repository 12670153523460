import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const formatToMinutes = (seconds) =>
  new Date(1000 * seconds).toTimeString().substring(3, 8);

function Countdown({ initialValue, onTimerEnd, formatTimer, className }) {
  const [secondsLeft, setSecondsLeft] = React.useState(initialValue);

  useEffect(() => {
    if (secondsLeft > 0) {
      const timer = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      onTimerEnd();
    }
  }, [initialValue, secondsLeft]);

  return (
    <h3 className={className}>
      {formatTimer ? formatToMinutes(secondsLeft) : secondsLeft}
    </h3>
  );
}

Countdown.propTypes = {
  initialValue: PropTypes.number.isRequired,
  onTimerEnd: PropTypes.func.isRequired,
  formatTimer: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

export default Countdown;
