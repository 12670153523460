import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import ErrorMessage from '../ErrorMessage';
import { Textarea } from './style';
import { readableColor, parseToRgb } from 'polished';

function ResponseTextField({
  placeholder,
  errors,
  disabled,
  defaultValue,
  backgroundColorOverlay,
}) {
  const [value, setValue] = useState(defaultValue || '');
  const textRef = useRef(null);

  const setTextareaHeight = (target) => {
    target.style.height = '200px';
    target.style.height = target.scrollHeight + 'px';
  };

  useEffect(() => {
    const { current } = textRef;

    if (current) {
      current.focus();
      setTextareaHeight(current);
    }
  }, []);

  const handleValueChange = (e) => {
    setValue(e.target.value);
    setTextareaHeight(e.target);
  };

  const color = React.useMemo(() => {
    if (
      !backgroundColorOverlay ||
      backgroundColorOverlay.includes('var(--') ||
      parseToRgb(backgroundColorOverlay)?.alpha < 0.6
    ) {
      return '#fff';
    } else {
      return readableColor(backgroundColorOverlay, '#35353f', '#fff');
    }
  }, [backgroundColorOverlay]);

  return (
    <>
      <Textarea
        data-testid="ResponseTextField/Textarea"
        ref={textRef}
        placeholder={placeholder}
        name="ugc_text_response[ugc_text]"
        value={value}
        onChange={handleValueChange}
        disabled={disabled}
        color={color}
      />
      {!_isEmpty(errors) && (
        <ErrorMessage text={errors.ugc_text[0]} position="right-top" />
      )}
    </>
  );
}

ResponseTextField.propTypes = {
  placeholder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  backgroundColorOverlay: PropTypes.string.isRequired,
};

ResponseTextField.defaultProps = {
  backgroundColorOverlay: 'rgba(0, 0, 0, 0.2)',
};

export default ResponseTextField;
