import React, { useState, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import SelectBoxSelector from './components/SelectBoxSelector';
import ButtonsSelector from './components/ButtonsSelector';
import { Label, MarginTop, ErrorMessage } from './style';
import BlockLabel from '../../shared/BlockLabel';

const CREATABLE_SELECT = 5;
const REGULAR_SELECT = 6;

const shouldRenderButtons = ({ titleSize, options, withOtherOption }) => {
  const optionsLength = options.length;
  const minimumForDropDown = withOtherOption
    ? CREATABLE_SELECT
    : REGULAR_SELECT;
  const renderButtons = optionsLength < minimumForDropDown;

  if (titleSize >= 472) {
    return false;
  } else if (renderButtons) {
    return true;
  } else {
    return false;
  }
};

function MultipleChoiceSelector(props) {
  const {
    currentValues,
    responseId,
    otherOptionId,
    options,
    withOtherOption,
    isMulti,
    disabled,
    responsive,
    ctaButtonContent,
    ctaButtonStyle,
    askLabel,
    isPreview,
  } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [isButtons, setIsButtons] = useState(shouldRenderButtons(props));

  useEffect(() => {
    setIsButtons(shouldRenderButtons(props));
  }, [props.options, props.withOtherOption]);

  let selector;
  const selectorProps = {
    responseId,
    currentValues,
    otherOptionId,
    options,
    withOtherOption,
    isMulti,
    disabled,
    responsive,
    setErrorMessage,
    ctaButtonContent,
    ctaButtonStyle,
    isPreview,
  };

  if (isButtons) {
    selector = <ButtonsSelector {...selectorProps} />;
  } else {
    selector = (
      <MarginTop>
        <SelectBoxSelector {...selectorProps} />
      </MarginTop>
    );
  }

  return (
    <>
      <Label data-testid="HelperText">
        {!_isEmpty(options) && <BlockLabel askLabel={askLabel} isPreview={isPreview} />}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Label>
      {selector}
    </>
  );
}

MultipleChoiceSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  withOtherOption: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool.isRequired,
  currentValues: PropTypes.array,
  currentOptionIds: PropTypes.number,
  otherOptionId: PropTypes.number,
  disabled: PropTypes.bool,
  titleSize: PropTypes.number.isRequired,
  responsive: PropTypes.bool,
  ctaButtonContent: PropTypes.string.isRequired,
};

export default MultipleChoiceSelector;
