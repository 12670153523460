import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 8px;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  flex-grow: 1;
  flex-basis: 0;

  &:first-child {
    margin-left: 0;
  }
`;

export const Button = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: ${(props) => (props.isChecked ? '#fff' : 'rgba(0, 0, 0, 0.2)')};
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: 500;
  margin: 0;
  padding: 12px 0;
  color: ${(props) => (props.isChecked ? '#000' : 'rgba(255, 255, 255, 0.8)')};
  cursor: pointer;
  letter-spacing: -0.5px;

  &:hover {
    border: 1px solid #fff;
  }
`;

export const DonationTypeWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto 10px auto;
  padding: 0;
  max-width: 200px;
  font-size: 13px;
  line-height: 1;
`;

export const DonationTypeTitle = styled.div`
  width: 100%;
  margin: 0.25rem;
  border-radius: 8px 8px 8px 8px;
  background-color: grey;
  text-align: center;
  padding: 0.35em 0;
`;
