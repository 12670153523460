import React, { useState, useEffect, useCallback } from 'react';

import Popover from '@mui/material/Popover';
import { useSlate } from 'slate-react';

import LinkIcon from '@mui/icons-material/Link';
import Button from '../ChangeButton';
import {
  Label,
  Container,
  ButtonGroup,
  PrimaryActionButton,
  ActionButton,
  InputWrapper,
} from '../LinksButton/style';
import { Input } from '../../../../../inputs/Input';
import { isUrl, insertLink, activeLink, unwrapLink } from '../LinksButton/utils';

function ResponderLinksButton({ onPopover }) {
  const editor = useSlate();
  const [selection, setSelection] = useState(null);
  const [anchoreEl, setAnchoreEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);

  const handleOpen = useCallback(
    (e) => {
      if (onPopover) {
        onPopover(true);
      }
      const link = activeLink(editor);

      if (link) {
        setInputValue(link[0].url);
      }

      setAnchoreEl(e.currentTarget);
      setSelection(editor.selection);
    },
    [editor],
  );
  const handleClose = () => {
    if (onPopover) {
      onPopover(false);
    }
    setAnchoreEl(null);
    setInputValue('');
    setSelection(null);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleUnlink = useCallback(
    (e) => {
      e.preventDefault();
      editor.selection = selection;
      if (activeLink(editor)) {
        unwrapLink(editor);
      }
      handleClose();
    },
    [selection],
  );
  const handleLink = useCallback(
    (e) => {
      e.preventDefault();
      editor.selection = selection;

      if (!error) {
        insertLink(editor, inputValue);
        handleClose();
      }
    },
    [inputValue, error, selection],
  );

  useEffect(() => {
    if (inputValue !== '' && !isUrl(inputValue)) {
      setError('is not valid');
    } else {
      setError(null);
    }
  }, [inputValue]);

  const open = Boolean(anchoreEl);
  const id = open ? 'simple-popover' : undefined;
  const isActive = activeLink(editor);
  const iconColor = isActive ? '#FFF' : '#333';

  return (
    <>
      <Button
        aria-describedby={id}
        isActive={isActive}
        handleClick={handleOpen}
        popover="Hyperlink"
        buttonProps={{
          'data-testid': 'TitleSection/LinkButton',
        }}
      >
        <LinkIcon sx={{ color: iconColor, fontSize: 22 }} />
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchoreEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            '&.MuiPaper-root': {
              marginTop: '4px',
              marginLeft: '-12px',
              borderRadius: '8px',
              overflow: 'initial',
            },
          },
        }}
      >
        <Container data-testid="TitleSection/HyperlinkDropdown">
          <Label>Url</Label>
          <InputWrapper>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              placeholder="https://example.com"
              error={error}
              autoFocus
            />
          </InputWrapper>
          <ButtonGroup>
            <ActionButton
              onClick={handleUnlink}
              data-testid="ResponderLinksButton/unlink"
            >
              Unlink
            </ActionButton>
            <PrimaryActionButton
              onClick={handleLink}
              data-testid="ResponderLinksButton/link"
            >
              Link
            </PrimaryActionButton>
          </ButtonGroup>
        </Container>
      </Popover>
    </>
  );
}

export default ResponderLinksButton;
