import React, { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import useUpdateReferencesTags from '../hooks/useUpdateReferencesTags';
import { Label } from './style';

const EditableReferenceLabel = ({ html, style }) => {
  const [content, setContent] = useState([]);
  const parsedTextWithReferences = useUpdateReferencesTags(html);

  useEffect(() => {
    setContent(
      parsedTextWithReferences.map((text) => {
        if (typeof text === 'string') {
          return text;
        }

        return (
          <Label style={style} key={nanoid()}>
            {text.name}
          </Label>
        );
      }),
    );
  }, [parsedTextWithReferences]);

  return <>{content}</>;
};

export default EditableReferenceLabel;
