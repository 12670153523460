import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ALL_ALLOWED_FILE_TYPES } from '../../../constants';
import { uploadMediaValidator } from '../../UploadMediaSection/uploadMediaValidator';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ErrorMessage } from '../../responder/MultipleChoiceSelector/components/ButtonsSelector/components/style';
import { Uploader } from '../../../uploader';
import ProgressBar from './ProgressBar';

export default function UploadField({
  helperText,
  name,
  defaultValue,
  cssStyle,
  isPreview,
  label,
}) {
  const [fileName, setFileName] = React.useState(defaultValue ? defaultValue[0].name : null);
  const [uploading, setUploading] = React.useState(false);
  const [signedId, setSignedId] = React.useState(
    defaultValue ? defaultValue[0].signed_id : '',
  );
  const [errorMessage, setErrorMessage] = React.useState(helperText);
  const progressPercentageRef = React.useRef(null);
  const progressBarRef = React.useRef(null);
  const activeUpload = React.useRef(false);

  const setActiveUploadRequest = (request) => {
    activeUpload.current = request;
  };

  const uploadCreateCallback = (error, { signed_id }) => {
    if (!error) {
      setSignedId(signed_id);
    }
  };

  const handleCompleteUpload = (file) => {
    setUploading(false);
    setFileName(file.name);
  };

  const handleCancelUpload = () => {
    if (activeUpload.current) {
      activeUpload.current.abort();
    }

    handleClear();
    setUploading(false);
  };

  const handleClear = () => {
    setSignedId('');
    setFileName(null);
  };

  const onDropAccepted = (acceptedFiles) => {
    setUploading(true);
    const uploader = new Uploader(
      acceptedFiles[0],
      '/rails/active_storage/direct_uploads',
      progressPercentageRef.current,
      progressBarRef.current,
      setActiveUploadRequest,
      () => handleCompleteUpload(acceptedFiles[0]),
    );

    uploader.create(uploadCreateCallback);
    setErrorMessage('');
  };

  const onDropRejected = (rejectedFiles) => {
    rejectedFiles.map(({ errors }) => {
      const errorMessage = errors[1] ? errors[1].message : errors[0].message;

      setErrorMessage(errorMessage);
      handleClear();
    });
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: ALL_ALLOWED_FILE_TYPES,
    noClick: true,
    multiple: false,
    validator: (file) => uploadMediaValidator(file, true),
    onDropAccepted,
    onDropRejected,
  });
  const style = React.useMemo(
    () => ({
      ...(isDragAccept ? { outline: '2px dashed var(--success)' } : {}),
      ...(isDragReject ? { outline: '2px dashed var(--error)' } : {}),
    }),
    [isDragAccept, isDragReject],
  );

  return (
    <>
      <Box sx={{ color: cssStyle.color || 'inherit' }}>{label}</Box>
      <Box
        {...getRootProps({ style })}
        sx={{
          position: 'relative',
          color: 'rgba(0, 0, 0, 0.6)',
          pointerEvents: isPreview ? 'none' : 'auto',
          ...cssStyle,
          ...(errorMessage && {
            borderColor: 'var(--error)',
            borderWidth: cssStyle.borderWidth === '0px'
              ? '1.5px'
              : cssStyle.borderWidth,
          }),
        }}
      >
        {errorMessage && (
          <ErrorMessage
            style={{
              color: '#fff',
              background: 'var(--error)',
              top: '-10px',
              right: '3px',
              padding: '2px 8px',
              marginTop: 0,
            }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
        {!fileName && !uploading && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              py: 4,
            }}
          >
            <CloudUploadOutlinedIcon
              aria-label="open upload dialog"
              sx={{ fontSize: 50, cursor: 'pointer' }}
              onClick={open}
            />
            <Typography variant="body2">
              Choose a file or drag it here.
            </Typography>
          </Box>
        )}
        {fileName && !uploading && (
          <Box
            sx={{
              height: 61,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
            }}
          >
            <Typography variant="body1" noWrap>{fileName}</Typography>
            <DeleteOutlineOutlinedIcon
              sx={{ cursor: 'pointer' }}
              aria-label="cancel upload"
              onClick={handleClear}
            />
          </Box>
        )}
        {!isPreview && (
          <ProgressBar
            visible={uploading}
            percentageRef={progressPercentageRef}
            progressBarRef={progressBarRef}
            color={cssStyle.color}
            onCancel={handleCancelUpload}
          />
        )}
      </Box>
      <input {...getInputProps()} />
      <input type="hidden" name={name} defaultValue={signedId} />
    </>
  );
}
