import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export default function ProgressBar({
  visible,
  percentageRef,
  progressBarRef,
  color,
  onCancel,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 61,
        position: 'static',
        width: '100%',
        px: 2,
        ...(!visible && {
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: '1px',
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: '1px',
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography variant="p">Uploading...</Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              mr: 1,
              bgcolor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                height: 2,
                bgcolor: color || 'primary.main',
              }}
              ref={progressBarRef}
            />
          </Box>
          <Box sx={{ justifySelf: 'end' }} ref={percentageRef}>
            0%
          </Box>
        </Box>
      </Box>
      <ClearOutlinedIcon
        sx={{ cursor: 'pointer', ml: 2 }}
        aria-label="cancel upload"
        onClick={onCancel}
      />
    </Box>
  );
}
