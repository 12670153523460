import styled from 'styled-components';
import { Button } from '../../../Button';
import Player from 'video-react/lib/components/Player';

export const PlayButton = styled(Button)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0.5;
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 23.4375rem;
    padding: 1rem 1rem;
    width: 6rem;
    height: 6rem;
    display: -webkit-inline-box;
    display: inline-flex;
    border-radius: 50%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 6.8rem;
    min-width: 5rem;
    z-index: 3;
  }
`;

export const ControlButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: inherit;
  left: auto;
  right: ${props => props.withShareIcon ? '48px' : '8px'};
  top: 12px;
  z-index: 3;

  @media (min-width: 375px) {
    top: 14px;
  }

  @media (min-width: 768px) {
    top: 18px;
  }

  @media (min-width: 961px) {
    top: ${props => props.withProgressBar ? '66px' : '16px'};
    right: 8px;
  }

  @media (min-width: 1024px) {
    top: ${props => props.withProgressBar ? '70px' : '22px'};
  }
`;

export const ControlButton = styled(Button)`
  && {
    height: 24px;
    width: 24px;
    padding: 0;
    border-width: 0;
    margin-right: 24px;
    background: transparent;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledPlayer = styled(Player)`
  position: relative;
  width: 100%;
  height: 100%;

  video {
    left: 0;
    object-fit: ${(props) =>
      props.layout === 'fit_to_size' ? 'contain' : 'cover'};
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }

    &::-webkit-media-controls-panel {
      display: none;
    }
  }
`;

export const ClickableBackground = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &:focus {
    outline: 0;
  }
`;
