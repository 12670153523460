import React from 'react';
import { File } from 'react-feather';

export function UploadedFile({ name }) {
  return (
    <div
      data-testid="UgcUploadResponse/FilenameContainer"
      className="ugc-file-name__container"
    >
      <div>
        <File size={20} />
      </div>
      <span style={{ marginLeft: '0.5rem' }}>{name}</span>
    </div>
  );
}
