import { DirectUpload } from '@rails/activestorage';

export class Uploader {
  constructor(blob, url, uploadPercentage, uploadProgress, cancelCallback, onComplete) {
    this.uploadPercentage = uploadPercentage;
    this.uploadProgress = uploadProgress;
    this.blob = blob;
    this.url = url;
    this.cancelCallback = cancelCallback;
    this.onComplete = onComplete;
  }

  create(callback) {
    const uploader = new DirectUpload(this.blob, this.url, this);
    uploader.create(callback);
  }

  directUploadWillStoreFileWithXHR(request) {
    this.cancelCallback(request);

    request.upload.addEventListener('progress',
      event => this._directUploadDidProgress(event));
    if (this.onComplete) {
      request.addEventListener('load', this.onComplete, false);
    }
  }

  _directUploadDidProgress(event) {
    const percent = Math.round(event.loaded / event.total * 100);

    this._updateProgressBar(percent);
  }

  _updateProgressBar(percent) {
    this.uploadPercentage.innerHTML = `${percent}%`;
    this.uploadProgress.style.width = `${percent}%`;
  }
}
