import React from 'react';
import Box from '@mui/material/Box';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';
import useUpdateReferencesTags from '../../hooks/useUpdateReferencesTags';
import { toHtmlWithStyledReferences } from '../../../utils';

export default function BlockLabel({ askLabel, isPreview }) {
  const label = askLabel?.richText.titleHtml;

  if (!label) {
    return null;
  }

  const styles = _omitBy(askLabel.cssStyle, _isEmpty);
  const parsedHtml = isPreview && useUpdateReferencesTags(label);
  const styledLabel = isPreview && toHtmlWithStyledReferences(parsedHtml);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        color: '#fff',
        borderStyle: 'solid',
        borderWidth: 0,
        m: '0 auto 1rem',
        pt: isPreview ? '12px' : 0,
        '& h1, h2, h3, h4, p': {
          m: 0,
        },
        '& .preview_reference': {
          bgcolor: 'rgba(114,114,120,0.2)',
          borderRadius: '4px',
          p: '2px 4px',
          m: '4px 2px',
        },
        ...styles,
      }}
      className="ha-form__label-text"
      dangerouslySetInnerHTML={{ __html: styledLabel || label }}
    />
  );
}
