import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingSpinner({ playButtonIsHidden, readyState }) {
  if (playButtonIsHidden && readyState > 3) {
    // Videos with autoplay and no play button start playing only after the file is downloaded
    return null;
  }

  if (!playButtonIsHidden && readyState > 2) {
    // For videos with play button hiding the spinner when downloading starts
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 50,
        height: 50,
        transform: 'translate(-50%, -50%)',
        zIndex: 3,
      }}
    >
      <CircularProgress size={48} sx={{ color: '#fff' }} />
    </Box>
  );
}
