import React from 'react';
import PropTypes from 'prop-types';
import wretch from 'wretch';
import useUpdateReferencesTags from '../../../../../hooks/useUpdateReferencesTags';
import { toHtmlWithStyledReferences } from '../../../../../../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from './Checkbox';
import { SingleSelectOptionButton, MultiSelectOptionButton } from './style';

function Button({
  responseId,
  isMulti,
  isPreview,
  checked,
  option,
  selected,
  disabled,
  onClick,
  cssStyle,
}) {
  const [multipleChoiceId, textTitle, htmlTitle] = option;
  let previewHtmlTitle;

  if (isPreview) {
    const parsedHtml = useUpdateReferencesTags(htmlTitle);
    previewHtmlTitle = toHtmlWithStyledReferences(parsedHtml);
  }

  const parentHandleClick = (e) => {
    e.preventDefault();

    onClick([multipleChoiceId, textTitle]);
  };

  const handleClick = (e) => {
    e.preventDefault();

    const params = {
      response: {
        multiple_choice_option_responses: [
          {
            multiple_choice_option_name: textTitle,
            multiple_choice_option_id: multipleChoiceId,
          },
        ],
      },
    };

    wretch(`/api/v1/responses/${responseId}/multiple_choices`)
      .content('application/json')
      .put(params)
      .json((json) => {
        window.location = json.redirect_url;
      })
      .catch((error) => {
        window.exceptionHandler.notify(error);
      });
  };

  return isMulti ? (
    <MultiSelectOptionButton
      role="button"
      style={cssStyle}
      color={cssStyle.color}
      onClick={parentHandleClick}
      checked={checked}
      disabled={disabled}
    >
      <Checkbox checked={checked} color={cssStyle.color} />
      <div dangerouslySetInnerHTML={{ __html: previewHtmlTitle || htmlTitle }} />
    </MultiSelectOptionButton>
  ) : (
    <div style={{ position: 'relative' }}>
      {selected && (
        <CheckCircleIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '1rem',
            transform: 'translateY(-50%)',
            color: cssStyle.color || 'var(--primary-color)',
            zIndex: 1,
          }}
        />
      )}
      <SingleSelectOptionButton
        role="button"
        data-selected={selected}
        style={cssStyle}
        color={cssStyle.color}
        onClick={handleClick}
        disabled={disabled}
        selected={selected}
        dangerouslySetInnerHTML={{ __html: previewHtmlTitle || htmlTitle }}
      />
    </div>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  option: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isMulti: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default Button;
