import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 48px;
`;

export const DropdownListContainer = styled.div`
  position: absolute;
  top: 0;
  display: grid;
  grid-template-rows: repeat(5, 48px);
  width: 48px;
  background-color: #d1d1d1;
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    outline: 0;
    margin: 0;
    color: #333;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: #fff;
      color: #333;
    }

    &:focus {
      outline: 0;
    }
  }
`;

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  right: 51px;
  transform: translateY(-50%);
  font-size: 11px;
  line-height: 1.45;
  padding: 5px 8px;
  background-color: #333;
  border-radius: 4px;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    right: -6px;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%) rotate(45deg);
    background-color: #333;
    border-radius: 2px;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;

  &:hover ${Tooltip} {
    display: block;
  }
`;

