import styled from 'styled-components';

export const Label = styled.span`
  background-color: rgba(114, 114, 120, 0.2);
  border-radius: 4px;
  color: inherit;
  padding: 2px 4px;
  margin: 4px 2px;
  outline: none;
`;
