import styled from 'styled-components';
import { darken } from 'polished';

export const OtherInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
`;

export const ActionButton = styled.button`
  position: absolute;
  width: auto;
  font-size: 0.875rem;
  border-radius: 8px;
  right: 0;
  border: 0px solid transparent;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  color: #727278;
  margin-top: 0;

  &:hover {
    background-color: #fff;
    color: ${darken(0.1, '#727278')};
  }

  &:focus {
    outline: none;
  }
`;
