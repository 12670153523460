import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import IconButton from '@mui/material/IconButton';

export default function Button({ attributes, children, ...rest }) {
  if (!attributes.enabled) {
    return <div />;
  }

  const cssStyle = _omitBy(attributes.cssStyle, _isEmpty);

  return (
    <IconButton
      sx={{
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 0,
        color: 'rgba(255, 255, 255, 1)',
        ...cssStyle,
        '&:hover': {
          color: 'rgba(255, 255, 255, 1)',
          ...cssStyle,
        },
        '&.MuiButtonBase-root.MuiIconButton-root.Mui-disabled': {
          color: 'rgba(255, 255, 255, 1)',
          opacity: 0.6,
          ...cssStyle,
        },
      }}
      {...rest}
    >
      {children}
    </IconButton>
  );
}
