import styled from 'styled-components';

export const Container = styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 0.64rem;
  letter-spacing: 0.125em;
  margin: 1.5rem auto;
  text-transform: uppercase;
  text-align: center;
`;

export const Link = styled.a`
  position: relative;
  color: ${(props) => props.color};
  text-decoration: none;
  z-index: 1;
  &:hover {
    color: ${(props) => props.color};
  }
  ${(props) =>
    props.withMediaBackground &&
    `
      @media (min-width: 961px) {
        position: absolute;
        left: -50%;
        bottom: 2rem;
        transform: translateX(-50%);
      }
  `}
`;
