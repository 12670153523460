import swipeDetect from 'swipe-detect';
import VideoPlayer from './video_player_controller';

import { subscribeTo } from '../sharedStore';

export default class extends VideoPlayer {
  static targets = [
    'container',
    'form',
    'buttonContainer',
    'redirectBack',
  ];

  timer = null;
  container = null;

  connect() {
    if (this.containerTarget.scrollHeight < this.responseAskTitleTarget.scrollHeight) {
      this.container = this.responseAskTitleTarget;
    } else {
      this.container = this.containerTarget;
    }

    if (!this.hasButtonContainerTarget && !this.isDisabled()) {
      this._bindRedirectActionListener();
      this._bindSwipeListener();
    }

    subscribeTo('videoEndedPlaying', this.redirectForward.bind(this));
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  redirectBack() {
    if (!this.hasButtonContainerTarget && !this.isDisabled()) {
      window.history.back();
    }
  }

  redirectForward() {
    if (!this.hasButtonContainerTarget && !this.isDisabled()) {
      this.formTarget.submit();
    }
  }

  _bindRedirectActionListener() {
    const timeout = parseInt(this.formTarget.dataset.timeout) * 1000;
    // We don't need create timeout for auto redirect when
    // conten timeout is null
    if (timeout) {
      this.timer = setTimeout(this.redirectForward.bind(this), timeout);
    }
  }

  _bindSwipeListener() {
    swipeDetect(this.container, function(direction) {
      switch (direction) {
        case 'right':
          this.redirectBack();
          break;
        case 'left':
          this.redirectForward();
          break;
      }
    }.bind(this));
  }

  isDisabled() {
    return this.data.get('disabled') === 'true';
  }
}
