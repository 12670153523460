import { Controller } from 'stimulus';
import wretch from 'wretch';
import { hideFlashMessage, renderFlashError } from '../flash_messages';
import { setValueToStore, subscribeTo } from '../sharedStore';

export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_BANK = 'us_bank_account';

export default class extends Controller {
  static targets = [
    'submit',
    'connectAccount',
    'connectAccountCard',
    'disclaimerChecked',
  ];

  sharedStoreErrorKeys = [];
  getCardToken = null;
  connectBankAccount = null;
  confirmBankAccountPayment = null;
  billingDetails = null;
  paymentType = null;
  amount = null;
  paymentMethods = [];
  selectedPaymentMethod = null;

  connect() {
    this._enableSubmit = this._enableSubmit.bind(this);
    this._setOriginalButtonContent = this._setOriginalButtonContent.bind(this);
    this._setButtonLoader = this._setButtonLoader.bind(this);
    this._handleSubmitSuccess = this._handleSubmitSuccess.bind(this);
    this._handleSubmitError = this._handleSubmitError.bind(this);
    this._handleConfirmError = this._handleConfirmError.bind(this);
    this._handleConnectAccountError = this._handleConnectAccountError.bind(
      this,
    );

    this.paymentType = this.data.get('ask-with-card') === 'true' ? PAYMENT_TYPE_CARD : PAYMENT_TYPE_BANK;

    this._whenStripeAvailable([
      this._enableSubmit,
      this._setOriginalButtonContent,
    ]);
  }

  async submit(event) {
    event.preventDefault();
    this.submitTarget.disabled = true;
    this._setButtonLoader();
    this._hideDataFieldsErrors();

    const responseUuid = this.data.get('response-uuid');

    const dataParams = {
      response: this._findDataParams(),
    };

    if (this.paymentType === PAYMENT_TYPE_CARD) {
      wretch(`/api/v1/responses/${responseUuid}/payment_intent`)
        .json(dataParams)
        .post()
        .json((data) => {
          this.getCardToken(
            data.payment_intent.client_secret,
            this.billingDetails || this._findBillingDetailsParams(),
          )
            .then((result) => {
              if (result.error) {
                this.submitTarget.disabled = false;
                this._setOriginalButtonContent();
                this._handleCreditCardErrors([
                  { error_message: result.error.message },
                ]);
              } else {
                hideFlashMessage();
                wretch(`/api/v1/responses/${responseUuid}/credit_card`)
                  .put()
                  .json(this._handleSubmitSuccess)
                  .catch(this._handleSubmitError);
              }
            })
            .catch(this._handleConfirmError);
        })
        .catch(this._handleSubmitError);
    }

    if (this.paymentType === PAYMENT_TYPE_BANK) {
      wretch(`/api/v1/responses/${responseUuid}/payment_intent`)
        .json(dataParams)
        .post()
        .json((data) => {
          this.confirmBankAccountPayment(data.payment_intent.client_secret)
            .then((result) => {
              if (result.error) {
                this.submitTarget.disabled = false;
                this._setOriginalButtonContent();
                this._handleCreditCardErrors([
                  { error_message: result.error.message },
                ]);
              } else {
                hideFlashMessage();
                wretch(`/api/v1/responses/${responseUuid}/credit_card`)
                  .put()
                  .json(this._handleSubmitSuccess)
                  .catch(this._handleSubmitError);
              }
            })
            .catch(() => {
              this.submitTarget.disabled = false;
              this._setOriginalButtonContent();

              this._addErrorToTarget(
                this.connectAccountCardTarget,
                'Please connect your bank account',
              );
            });
        })
        .catch(this._handleSubmitError);
    }
  }

  async connectAccount(event) {
    if (!event) {
      return;
    }

    event.preventDefault();
    this._hideDataFieldsErrors();
    this.connectAccountTarget.disabled = true;

    const responseUuid = this.data.get('response-uuid');

    const dataParams = {
      response: this._findDataParams(),
    };

    wretch(`/api/v1/responses/${responseUuid}/payment_intent`)
      .json(dataParams)
      .post()
      .json((data) => {
        this._enableSubmit();
        this.connectAccountTarget.disabled = false;
        this.connectBankAccount(
          data.payment_intent.client_secret,
          this.billingDetails || this._findBillingDetailsParams(),
        );
      })
      .catch((err) => {
        this.connectAccountTarget.disabled = false;
        this._handleConnectAccountError(err);
      });
  }

  selectCardType() {
    if (this.paymentType === PAYMENT_TYPE_BANK) {
      this.paymentType = PAYMENT_TYPE_CARD;
      this.selectedPaymentMethod = this.paymentMethods.find(
        (m) => m.kind === PAYMENT_TYPE_CARD,
      );
      this._setOriginalButtonContent();
    }
  }

  selectBankType() {
    if (this.paymentType === PAYMENT_TYPE_CARD) {
      this.paymentType = PAYMENT_TYPE_BANK;
      this.selectedPaymentMethod = this.paymentMethods.find(
        (m) => m.kind === PAYMENT_TYPE_BANK,
      );
      this._setOriginalButtonContent();
    }
  }

  _whenStripeAvailable = (callbacks) => {
    const self = this;

    subscribeTo('getCardToken', (func) => {
      self.getCardToken = func;
      callbacks.map((fn) => fn());
    });

    subscribeTo('connectBankAccount', (func) => {
      self.connectBankAccount = func;
    });

    subscribeTo('confirmBankAccountPayment', (func) => {
      self.confirmBankAccountPayment = func;
    });

    subscribeTo('amount', (value) => {
      self.amount = value;
    });

    subscribeTo('paymentMethods', (methods) => {
      self.paymentMethods = methods;
    });

    subscribeTo('selectedPaymentMethod', (obj) => {
      self.selectedPaymentMethod = obj;
    });

    subscribeTo('billingDetails', (value) => {
      self.billingDetails = value;
    });
  };

  // subscribe to the selected payment method
  // by default this subscribed variable should return card with funding `credit`
  // update the button based on the amount of the selected payment method
  // update the disclaimer based on the amount of the selected payment method
  // confirming the payment should either happen in controller or component but should be consistent

  _enableSubmit() {
    this.submitTarget.disabled = false;
  }

  _setButtonLoader() {
    this.submitTarget.innerHTML = `
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    `;
  }

  _setOriginalButtonContent() {
    const originalContent = this.submitTarget.dataset.originalContent;
    this.submitTarget.innerHTML = this._replaceAmountWithTotal(originalContent);
  }

  _replaceAmountWithTotal(text) {
    const totalAmount = this.selectedPaymentMethod?.total;
    return totalAmount ? text.replace(this.amount, totalAmount) : text;
  }

  _findDataParams() {
    const params = {
      payment_method: this.paymentType,
      payment_method_card_funding: this.selectedPaymentMethod?.card_funding,
    };

    // Build data fields responses here
    const dataFieldsWrappers = Array.from(
      document.querySelectorAll('[data-testid*="DataFieldsSelector/Field_"]'),
    );
    const preparedAttributes = dataFieldsWrappers.reduce((acc, field) => {
      const fieldValue = field.querySelector('[name*="[value]"').value;
      const idHiddenInput = field.querySelector(
        'input[type="hidden"][name*="[id]"]',
      );
      const dataFieldIdHiddenInput = field.querySelector(
        'input[type="hidden"][name*="[data_field_id]"]',
      );
      const mcOptionIdHiddenInput = field.querySelector(
        'input[type="hidden"][name*="[mc_option_id]"]',
      );
      const richTextTitleInput = field.querySelector(
        'input[type="hidden"][name*="[value][title]"]',
      );

      const attributes = {
        data_field_id: dataFieldIdHiddenInput.value,
        value: fieldValue,
      };

      if (idHiddenInput) {
        attributes.id = idHiddenInput.value;
      }

      if (mcOptionIdHiddenInput) {
        attributes.mc_option_id = mcOptionIdHiddenInput.value;
      }

      if (richTextTitleInput) {
        const richTextTitleHtmlInput = field.querySelector(
          'input[type="hidden"][name*="[value][title_html]"]',
        );
        const richTextTitleElementsInput = field.querySelector(
          'input[type="hidden"][name*="[value][title_elements]"]',
        );

        attributes.value = {
          title: richTextTitleInput.value,
          title_html: richTextTitleHtmlInput.value,
          title_elements: richTextTitleElementsInput.value,
        };
      }

      acc.push(attributes);
      return acc;
    }, []);

    params.data_field_responses_attributes = preparedAttributes;
    if (this.hasDisclaimerCheckedTarget) {
      const disclaimerValue = this.disclaimerCheckedTarget.querySelector(
        'input',
      ).checked;

      params.disclaimer_checked = disclaimerValue ? 'on' : 'off';
    }

    return params;
  }

  _findBillingDetailsParams() {
    const fieldTypes = [
      'email',
      'full_name',
      'first_name',
      'last_name',
      'zip_code',
    ];
    const params = fieldTypes.reduce((acc, fieldType) => {
      const field = document.querySelector(`[data-testtype="${fieldType}"]`);

      if (field) {
        const value = field.querySelector('[name*="[value]"').value;

        switch (fieldType) {
          case 'email':
            acc.email = value;
            break;
          case 'full_name':
            acc.name = value;
            break;
          case 'first_name':
            acc.name = acc.name ? `${value} ${acc.name}` : value;
            break;
          case 'last_name':
            acc.name = acc.name ? `${acc.name} ${value}` : value;
            break;
          case 'zip_code':
            acc.address = { postal_code: value };
            break;
        }
      }

      return acc;
    }, {});

    return params;
  }

  _handleSubmitSuccess({ data: { attributes } }) {
    this._setOriginalButtonContent();
    const redirect = () => {
      Turbo.visit(attributes.redirectUrl);
    };

    if (typeof fbq !== 'undefined') {
      fbq('trackCustom', 'ExperiencePayment', {
        experience_id: attributes.experienceUuid,
        experience_name: attributes.experienceTitle,
        value: attributes.donationAmount,
        currency: attributes.currency,
        payment_type: attributes.recurring,
      });

      // We need timeout here to fire Facebook Pixel event before redirect
      setTimeout(redirect, 300);
    } else {
      redirect();
    }
  }

  _handleConfirmError(text) {
    this.submitTarget.disabled = false;
    this._setOriginalButtonContent();
    const errors = JSON.parse(text);

    this._handleDataErrors(errors);
  }

  _handleSubmitError({ text }) {
    this.submitTarget.disabled = false;
    this._setOriginalButtonContent();

    try {
      this._handleDataErrors(JSON.parse(text));
    } catch (e) {
      this._showFlashErrorMessage();
    }
  }

  _handleCreditCardErrors(errors) {
    if (!errors) {
      return;
    }
    const messages = errors[0];

    if (messages.already_charged) {
      renderFlashError(messages.already_charged, 'success');
    } else if (messages.error_message) {
      renderFlashError(messages.error_message);
    }
    setTimeout(hideFlashMessage, 3000);
  }

  _handleDataErrors(json) {
    if (!json) {
      return;
    }
    const messages = json.errors;

    for (const [key, value] of Object.entries(messages)) {
      this.sharedStoreErrorKeys.push(key);

      setValueToStore(`dataFieldError_${key}`, value[0]);
    }
    if (messages.disclaimer_checked) {
      this._addErrorToTarget(
        this.disclaimerCheckedTarget,
        messages.disclaimer_checked[0],
      );
    }
  }

  _handleConnectAccountError({ text }) {
    try {
      this._handleDataErrors(JSON.parse(text));
    } catch (e) {
      this._showFlashErrorMessage();
    }
  }

  _addErrorToTarget(target, message) {
    const errorEl = document.createElement('span');
    errorEl.classList.add('error');
    errorEl.innerText = message;

    target.classList.add('field_with_errors');
    target.appendChild(errorEl);
  }

  _removeErrorClass(target) {
    if (target.classList.contains('field_with_errors')) {
      target.classList.remove('field_with_errors');

      target.childNodes.forEach((el) => {
        if (el.tagName === 'SPAN' && el.classList.contains('error')) {
          el.remove();
        }
      });
    }
  }

  _hideDataFieldsErrors() {
    for (const key of this.sharedStoreErrorKeys) {
      setValueToStore(`dataFieldError_${key}`, null);
    }

    if (this.hasDisclaimerCheckedTarget) {
      this._removeErrorClass(this.disclaimerCheckedTarget);
    }

    if (this.hasConnectAccountCardTarget) {
      this._removeErrorClass(this.connectAccountCardTarget);
    }
  }

  _showFlashErrorMessage() {
    renderFlashError('Something went wrong. Try again later');
    setTimeout(hideFlashMessage, 3000);
  }
}
