import { Controller } from 'stimulus';
import * as clipboard from 'clipboard-polyfill';

export default class extends Controller {
  static targets = ['source'];

  copy() {
    clipboard.writeText(this.sourceTarget.value);
  }
}
