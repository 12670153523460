import React from 'react';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import _uniqueId from 'lodash/uniqueId';
import { ChevronDown } from 'react-feather';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import {
  styles,
  getCustomizedFormControlsStyles,
  getCustomizedNativeSelectStyles,
} from './style';

export const Select = ({
  options,
  name,
  label,
  defaultValue,
  onFocus,
  helperText,
  isCurrentlyEmployed,
  cssStyle,
  error,
  onSelect,
  disabled,
  isPreview,
}) => {
  const [value, setValue] = React.useState(
    _isNil(defaultValue) ? '' : defaultValue,
  );
  const ref = React.useRef(null);
  const color = cssStyle.color;

  React.useEffect(() => {
    if (ref && ref.current && error)  {
      ref.current.scrollIntoView();
    }
  }, []);

  const handleChange = (event) => {
    if (onSelect) {
      onSelect(event);
    }

    setValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        ...styles.formControl,
        ...getCustomizedFormControlsStyles(cssStyle),
        mt: cssStyle.marginTop,
        mr: cssStyle.marginRight,
        mb: cssStyle.marginBottom,
        ml: cssStyle.marginLeft,
      }}
      error={error}
    >
      <InputLabel
        htmlFor="data_response_country"
        sx={{
          ...styles.inputLabel,
          /* centering label when padding styles customized */
          marginTop: cssStyle.paddingTop || '14px',
          /* */
          color: color || 'rgba(0, 0, 0, 0.6)',
          opacity: color ? 0.75 : 1,
          fontSize: isCurrentlyEmployed ? '12px' : '1rem',
          transform: isCurrentlyEmployed
            ? 'translate(16px, -12px)'
            : 'translate(16px, -1px)',
          '&.Mui-focused': {
            color: color || 'rgba(0, 0, 0, 0.6)',
          },
          '& a, & a:hover': {
            color: color || 'var(--link-color)',
          },
        }}
      >
        {label}
      </InputLabel>
      <NativeSelect
        ref={ref}
        sx={{
          ...styles.select,
          ...getCustomizedNativeSelectStyles(cssStyle),
        }}
        value={isPreview ? defaultValue : value}
        onChange={handleChange}
        IconComponent={ChevronDown}
        disabled={disabled}
        input={
          <InputBase name={name} id="data_response_country" onFocus={onFocus} />
        }
      >
        {options.map(({ value, name, id }) => (
          <option key={_uniqueId()} value={value} data-id={id}>
            {name}
          </option>
        ))}
      </NativeSelect>
      {error && (
        <FormHelperText sx={styles.formHelperText}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  cssStyle: PropTypes.object.isRequired,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onFocus: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
