import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
} from 'react-share';
import { Share2, Link, Facebook, Twitter, Mail } from 'react-feather';
import { ShareIconWrapper } from '../../style';
import {
  Container,
  DropdownListContainer,
  ButtonWrapper,
  Tooltip,
} from './style';

const tooltips = {
  copy: 'Copy share link',
  facebook: 'Share on Facebook',
  twitter: 'Share on Twitter',
  email: 'Share via email',
};

const Dropdown = ({ shareUrl }) => {
  const [isOpenOnHover, setIsOpenOnHover] = React.useState(false);
  const [isOpenPermanently, setIsOpenPermanently] = useState(false);
  const [copyTooltipText, setCopyTooltipText] = useState(tooltips.copy);
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    setIsOpenOnHover(false);
    setIsOpenPermanently(false);
  };
  const openOnHover = () => setIsOpenOnHover(true);
  const closeOnHover = () => setIsOpenOnHover(false);
  const openPermanently = () => setIsOpenPermanently(true);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(shareUrl);
    const standardMessage = tooltips.copy;
    setCopyTooltipText('Link copied to clipboard!');

    setTimeout(() => {
      setCopyTooltipText(standardMessage);
    }, 2000);
  };

  return (
    <Container
      onMouseEnter={openOnHover}
      onMouseLeave={closeOnHover}
      ref={dropdownRef}
    >
      <ShareIconWrapper
        data-testid="SocialShare/ShareButton"
        isOpen={isOpenPermanently || isOpenOnHover}
        onClick={openPermanently}
      >
        <Share2 />
      </ShareIconWrapper>
      {(isOpenPermanently || isOpenOnHover) && (
        <DropdownListContainer data-testid="SocialShare/DropdownListContainer">
          <span></span>
          <ButtonWrapper>
            <button
              data-testid="SocialShare/CopyButton"
              onClick={copyToClipBoard}
            >
              <Link />
            </button>
            <Tooltip data-testid="SocialShare/CopyButtonTooltip">
              {copyTooltipText}
            </Tooltip>
          </ButtonWrapper>
          <ButtonWrapper onClick={closeDropdown}>
            <FacebookShareButton
              url={shareUrl}
              data-testid="SocialShare/FacebookButton"
              resetButtonStyle={false}
            >
              <Facebook />
            </FacebookShareButton>
            <Tooltip>{tooltips.facebook}</Tooltip>
          </ButtonWrapper>
          <ButtonWrapper onClick={closeDropdown}>
            <TwitterShareButton
              data-testid="SocialShare/TwitterButton"
              url={shareUrl}
              resetButtonStyle={false}
            >
              <Twitter />
            </TwitterShareButton>
            <Tooltip>{tooltips.twitter}</Tooltip>
          </ButtonWrapper>
          <ButtonWrapper onClick={closeDropdown}>
            <EmailShareButton
              openShareDialogOnClick
              url={shareUrl}
              data-testid="SocialShare/EmailButton"
              resetButtonStyle={false}
            >
              <Mail />
            </EmailShareButton>
            <Tooltip>{tooltips.email}</Tooltip>
          </ButtonWrapper>
        </DropdownListContainer>
      )}
    </Container>
  );
};

Dropdown.propTypes = {
  shareUrl: PropTypes.string.isRequired,
};

export default Dropdown;
