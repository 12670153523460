import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import wretch from 'wretch';

import {
  SingleSelectOptionButton,
  ActionButtonRounded,
  OtherButtonInputRounded,
  OtherButtonContainer,
  ErrorMessage,
} from './style';

const disabledOtherButton = (
  <SingleSelectOptionButton disabled={true}>
    + Add Option
  </SingleSelectOptionButton>
);

function OtherButton({
  currentValue,
  otherOptionId,
  disabled,
  selected,
  responseId,
}) {
  const [otherName, setOtherName]       = useState(currentValue);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEditing, setIsEditing]       = useState(otherName ? true : false);

  const handleChange = (e) => {
    setOtherName(e.target.value);
    // Hide error message on typing
    setErrorMessage(null);
  };

  // Call click() on Button on Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit(event);
    }
  };

  const showInput = (e) => {
    e.preventDefault();

    setIsEditing(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      response: {
        multiple_choice_option_responses: [
          {
            multiple_choice_option_name: otherName,
            multiple_choice_option_id: otherOptionId,
          },
        ],
      },
    };

    wretch(`/api/v1/responses/${responseId}/multiple_choices`).content('application/json').
      put(params).
      json(json => {

        window.location = json.redirect_url;
      }).catch((error) => {
        const message = JSON.parse(error.message).errors.multiple_choice_option_responses.multiple_choice_option_name[0];

        setErrorMessage(message);
      });
  };

  if (disabled) {
    return disabledOtherButton;
  }

  return (
    isEditing ?
      <OtherButtonContainer>
        {selected && (
          <CheckCircleIcon
            sx={{
              position: 'absolute',
              top: '21px',
              left: '1rem',
              color: 'var(--primary-color)',
              zIndex: 1,
            }}
          />
        )}
        <OtherButtonInputRounded
          placeholder='Type Other'
          value={otherName}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          autoFocus={true}
          data-testid='otherButton/input'
        />
        { errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ActionButtonRounded
          onClick={handleSubmit}
          data-testid='otherButton/submitButton'
          data-selected={selected}
        >
          Submit
        </ActionButtonRounded>
      </OtherButtonContainer> :
      <SingleSelectOptionButton
        role="button"
        onClick={showInput}
      >
        + Add Option
      </SingleSelectOptionButton>
  );
};

OtherButton.propTypes = {
  disabled: PropTypes.bool,
  responseId: PropTypes.string,
  otherOptionId: PropTypes.number,
  currentValue: PropTypes.string,
};

export default OtherButton;
