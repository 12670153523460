import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const borderRadius = '4px';
const ProgressContainer = styled.div`
  height: 4px;
  background-color: #E0E0E0;
  border-radius: ${borderRadius};
  margin-top: 9px;
  max-width: 758px;
  margin-right: auto;
  margin-left: auto;
`;

const Progress = styled.div`
  height: inherit;
  width: ${({ amount }) => amount};
  background-color: #39B34A;
  border-top-left-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  border-top-right-radius: ${({ isFull }) => isFull && borderRadius};
  border-bottom-right-radius: ${({ isFull }) => isFull && borderRadius};
`;

function ProgressBar({ full, current }) {
  let progress;

  if (current >= full) {
    progress = 100;
  } else {
    progress = current / full * 100;
  }

  return (
    <ProgressContainer>
      <Progress
        amount={`${progress}%`}
        isFull={progress === 100}
        data-percent={progress}
      />
    </ProgressContainer>
  );
}

ProgressBar.propTypes = {
  full: PropTypes.number,
  current: PropTypes.number,
};

export default ProgressBar;
