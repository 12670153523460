import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import AmountButtons from './components/AmountButtons';
import { InlineEditInput } from '../../inputs/Input/InlineEditInput';
import { DonationTypeTitle, DonationTypeWrapper } from './style';
import { CURRENCY_SIGN_MAP } from '../../../constants';
import DonationTypeSelector from './components/DonationTypeSelector';
import { StyledErrorMessage } from '../../inputs/Input/style';
import Box from '@mui/material/Box';
import CtaButton from '../../shared/CtaButton';
import BlockLabel from '../../shared/BlockLabel';

const isOtherAmount = (amounts, amount) => {
  return !amounts.includes(amount);
};

const parseDefaultAmount = (error, currentAmount, amounts) => {
  if (error) {
    return currentAmount;
  }

  const amount = parseInt(currentAmount);

  return amount ? amount : amounts[0];
};

const DONATION_TYPE_VALUES = {
  both: 'false',
  one_time: 'false',
  recurring: 'true',
};

const getDonationType = (defaultDonationType, paymentType) => {
  return paymentType
    ? (paymentType == 'monthly_recurring').toString()
    : DONATION_TYPE_VALUES[defaultDonationType];
};

function DonationAmountsSelector({
  paymentOptions,
  donationType,
  defaultDonationType,
  currentAmount,
  isCustomDonation,
  currency,
  error,
  ctaButtonContent,
  ctaButtonStyle,
  ctaButtonDisabled,
  paymentType,
  isPreview,
  disabledFields,
  askLabel,
}) {
  const amounts = paymentOptions.map(
    (option) => option.attributes.amountNumber,
  );

  const defaultCheckedAmount = parseDefaultAmount(
    error,
    currentAmount,
    amounts,
  );

  const [checkedAmount, setCheckedAmount] = useState(defaultCheckedAmount);
  const isOther = isOtherAmount(amounts, checkedAmount);
  const [otherChecked, setOtherChecked] = useState(error || isOther);
  const [otherAmount, setOtherAmount] = useState(isOther ? currentAmount : '');
  const [errorMessage, setErrorMessage] = useState(error);
  const currencySymbol = CURRENCY_SIGN_MAP[currency];
  const [donationTypeValue, setDonationTypeValue] = useState(
    getDonationType(defaultDonationType, paymentType),
  );

  useEffect(() => {
    setDonationTypeValue(getDonationType(defaultDonationType, paymentType));
  }, [defaultDonationType]);

  const buttonTitle = useMemo(() => {
    let title = ctaButtonContent;
    title = title.replace(
      '{payment_amount}',
      `${currencySymbol}${checkedAmount}`,
    );
    title = title.replace(
      '{payment_frequency}',
      `${donationTypeValue === 'true' ? 'Monthly' : ''}`,
    );

    return title;
  }, [ctaButtonContent, checkedAmount, currencySymbol, donationTypeValue]);

  const isOtherChecked = () => {
    return otherChecked || isOtherAmount(amounts, checkedAmount);
  };

  const checkOtherButton = () => {
    setOtherChecked(true);

    setCheckedAmount(otherAmount);
  };

  const checkButton = (amount) => () => {
    setOtherChecked(false);
    setErrorMessage(null);

    setCheckedAmount(amount);
  };

  const handleInput = (e) => {
    e.preventDefault();
    const value = e.target.value;

    setErrorMessage(null);
    setOtherAmount(value);
    setCheckedAmount(value);
  };

  const otherButtonPlaceholder = () => {
    if (otherAmount) {
      return otherAmount;
    }

    return isOtherChecked() ? 'Enter amount' : 'Other amount';
  };

  const haveNotOneAmountOption = amounts.length !== 1 || isCustomDonation;
  const donationTypeIsBoth = donationType === 'both';
  const donationTypeIsRecurring = donationType === 'recurring';

  return (
    <Box sx={{ position: 'relative' }}>
      <BlockLabel askLabel={askLabel} isPreview={isPreview} />
      {errorMessage && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            right: '-10px',
            top: '-8px',
          }}
        >
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        </Box>
      )}
      <input
        type="hidden"
        name="donation_amount_response[recurring]"
        value={donationTypeValue}
      />
      {donationTypeIsBoth && (
        <DonationTypeSelector
          value={donationTypeValue}
          setDonationType={setDonationTypeValue}
          disabled={disabledFields}
        />
      )}
      {donationTypeIsRecurring && haveNotOneAmountOption && (
        <DonationTypeWrapper>
          <DonationTypeTitle>Monthly</DonationTypeTitle>
        </DonationTypeWrapper>
      )}
      {(haveNotOneAmountOption || donationTypeIsBoth) && (
        <AmountButtons
          paymentOptions={paymentOptions}
          currentAmount={checkedAmount}
          onClick={checkButton}
          currency={currencySymbol}
          withCustom={isCustomDonation}
          disabled={disabledFields}
          isPreview={isPreview}
        />
      )}
      {isCustomDonation && (
        <InlineEditInput
          onClick={checkOtherButton}
          isChecked={isOtherChecked()}
          type="string"
          icon={currencySymbol}
          postfixIcon={currency}
          onChange={handleInput}
          autoFocus={isOtherChecked()}
          noMargin={true}
          value={otherAmount}
          placeholder={otherButtonPlaceholder()}
          disabled={ctaButtonDisabled || disabledFields}
          data-testid="DonationAmountsSelector/otherField"
        />
      )}
      <input
        type="hidden"
        name="donation_amount_response[amount]"
        value={checkedAmount}
      />
      <CtaButton
        className="ha-form__submit-button"
        type="submit"
        name="donation_amount_response[amount]"
        onClick={(e) => {
          e.preventDefault();

          const form = document.querySelector('form');
          form.requestSubmit(e.currentTarget);
        }}
        value={checkedAmount}
        disabled={ctaButtonDisabled}
        ctaButtonStyle={ctaButtonStyle}
        ctaButtonContent={buttonTitle}
        noMargin
      />
    </Box>
  );
}

DonationAmountsSelector.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  donationType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  currentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isCustomDonation: PropTypes.bool,
  error: PropTypes.string,
  ctaButtonContent: PropTypes.string,
  ctaButtonDisabled: PropTypes.bool,
  paymentType: PropTypes.string,
  disabledFields: PropTypes.bool,
};

DonationAmountsSelector.defaultProps = {
  ctaButtonContent: '',
  ctaButtonDisabled: false,
};

export default DonationAmountsSelector;
