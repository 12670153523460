import {hideElement, showElement} from 'utils';

export const hideFlashMessage = (message) => {
  const flashContainer = document.getElementById('error-flash-container');

  hideElement(flashContainer);
};

export const renderFlashError = (message, type) => {

  const messageClass = type ? `flash-${type}` : 'flash-error';
  const flashContainer = document.getElementById('error-flash-container');
  const wrappedMessage = `<div class='${messageClass}'>${message}</div>`;

  flashContainer.innerHTML = wrappedMessage;

  showElement(flashContainer);
};
