export const styles = {
  textField: {
    width: '100%',
    '& .MuiInput-formControl': {
      marginTop: 0,
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-root': {
      position: 'relative',
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.MuiFormLabel-filled': {
      transform: 'translate(16px, -15px)',
      fontSize: '12px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      transform: 'translate(16px, -15px)',
      fontSize: '12px',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#ff432e',
    },
    '& .MuiInputBase-root.Mui-error .MuiInputBase-input': {
      border: '1.5px solid #ff432e',
    },
    '& .MuiInputBase-root.MuiFilledInput-root': {
      '&:hover, &.Mui-focused, &.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(16px, -15px)',
      fontSize: '12px',
      backgroundColor: 'transparent',
      marginTop: '17px',
    },
    '& .MuiInputLabel-filled.Mui-error': {
      transform: 'translate(16px, -15px)',
      fontSize: '12px',
      backgroundColor: 'transparent',
      marginTop: '17px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#fff',
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '12px',
      zIndex: 1,
      '& .MuiButtonBase-root': {
        padding: '6px',
        mr: 0,
      },
      '&.Mui-error .MuiFilledInput-input': {
        border: '1.5px solid #ff432e',
      },
    },
    '& .MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
      pr: 7,
    },
  },
  input: {
    p: 0,
    '& .MuiInputBase-input': {
      height: '1.1876em',
      lineHeight: '1.1876em',
      padding: '20px 16px 6px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
      MozAppearance: 'textfield',
    },
    '& .MuiInputBase-input::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  inputLabel: {
    lineHeight: 1.3,
    fontWeight: 500,
    margin: '17px 0 8px',
    letterSpacing: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 32px)',
    transition: 'transform 0.2s, font-size 0.2s',
  },
  formHelperText: {
    position: 'absolute',
    top: '-10px',
    right: '-8px',
    padding: '2px 8px',
    backgroundColor: '#ff432e',
    borderRadius: '6px',
    zIndex: 1,
  },
};
