import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import BackgroundImage from './components/BackgroundImage';
import BackgroundVideo from './components/BackgroundVideo';

const mediaStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

function BackgroundOverlay({
  settings,
  videoSources,
  imageUrl,
  placeholderImageUrl,
}) {
  const {
    backgroundMediaOpacity,
    backgroundColorOverlay,
    backgroundMediaIsImage,
    backgroundMediaIsVideo,
  } = settings;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,

        '& img, video': mediaStyles,
      }}
      data-testid="BackgroundOverlay/Container"
    >
      {backgroundMediaIsImage && (
        <BackgroundImage
          imageUrl={imageUrl}
          placeholderImageUrl={placeholderImageUrl}
          backgroundMediaOpacity={backgroundMediaOpacity}
        />
      )}
      {backgroundMediaIsVideo && (
        <BackgroundVideo
          placeholderImageUrl={placeholderImageUrl}
          videoSources={videoSources}
          backgroundMediaOpacity={backgroundMediaOpacity}
        />
      )}
      <Box
        sx={mediaStyles}
        data-testid="BackgroundOverlay/Color"
        style={{
          backgroundColor: backgroundColorOverlay,
        }}
      />
    </Box>
  );
}

BackgroundOverlay.propTypes = {
  settings: PropTypes.shape({
    backgroundMediaOpacity: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    backgroundColorOverlay: PropTypes.string,
    backgroundMediaIsImage: PropTypes.bool,
    backgroundMediaIsVideo: PropTypes.bool,
  }).isRequired,
  videoSources: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default BackgroundOverlay;
