import styled from 'styled-components';

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;

  ${(props) =>
    props.backgroundIsColor &&
    `
    @media (min-width: 961px) {
      max-width: 600px;
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`;
