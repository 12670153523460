import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@mui/material/TextField';
import { styles } from './style';
import { getLabelMarginTop } from '../utils';

export const TextField = ({
  name,
  defaultValue,
  label,
  error,
  helperText,
  onFocus,
  disabled,
  multiline,
  isPreview,
  cssStyle,
  onChange,
  type = 'text',
  ...props
}) => {
  const valueAttribute = isPreview ? { value: defaultValue } : { defaultValue };
  const color = cssStyle.color;
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref && ref.current && error) {
      ref.current.scrollIntoView();
    }
  }, []);

  return (
    <MUITextField
      ref={ref}
      sx={{
        ...styles.textField,
        mt: cssStyle.marginTop,
        mr: cssStyle.marginRight,
        mb: cssStyle.marginBottom,
        ml: cssStyle.marginLeft,
        '& .MuiInputAdornment-root .MuiButtonBase-root:hover': {
          color: color || 'rgba(0, 0, 0, 0.6)',
        },
        '& .MuiInputAdornment-positionEnd .MuiButtonBase-root:hover svg': {
          opacity: 1,
        },
        '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused, & .MuiInputAdornment-positionEnd span, & .MuiInputAdornment-positionEnd .MuiButtonBase-root svg': {
          color: color || 'rgba(0, 0, 0, 0.6)',
          opacity: color ? 0.75 : 1,
        },
        '& .MuiFormLabel-root a, & .MuiFormLabel-root a:hover': {
          color: color || 'var(--link-color)',
        },
        '& .MuiInputAdornment-positionEnd .MuiButtonBase-root.Mui-disabled svg': {
          color: color || 'rgba(0, 0, 0, 0.26)',
          opacity: color ? 0.4 : 1,
        },
      }}
      type={type}
      name={name}
      defaultValue={defaultValue}
      label={label}
      error={error}
      disabled={disabled}
      variant="filled"
      helperText={helperText}
      onFocus={onFocus}
      onChange={onChange}
      multiline={multiline}
      rows={3}
      inputRef={props.inputRef}
      inputProps={props.inputProps}
      {...valueAttribute}
      InputProps={{
        disableUnderline: true,
        sx: {
          ...styles.input,
          '& .MuiFilledInput-input': {
            ...cssStyle,
            color: color || 'rgba(0, 0, 0, 0.87)',
            m: 0,
          },
          '& .MuiFilledInput-input:hover': {
            ...cssStyle,
            m: 0,
          },
          '& .MuiFilledInput-input:active': {
            ...cssStyle,
            m: 0,
          },
          '& .MuiFilledInput-input:focus': {
            ...cssStyle,
            m: 0,
          },
          '& .MuiFilledInput-input:disabled': {
            ...cssStyle,
            m: 0,
          },
          '& .MuiFilledInput-input:disabled:hover': {
            border: 'none',
            ...cssStyle,
            m: 0,
          },
          '& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
            color: color || 'rgba(0, 0, 0, 0.38)',
            WebkitTextFillColor: color || 'rgba(0, 0, 0, 0.38)',
          },
        },
        ...props.InputProps,
      }}
      InputLabelProps={{
        disableAnimation: true,
        sx: {
          '&.MuiFormLabel-root': {
            ...styles.inputLabel,
            /* centering label when padding styles customized */
            marginTop: getLabelMarginTop(cssStyle.paddingTop, '17px'),
            /* */
            fontSize: error ? '12px' : '1rem',
            transform: `translate(16px, ${error ? '-15px' : '-4px'})`,
            background: 'transparent',
            '&.MuiInputLabel-filled': {
              background: 'transparent',
            },
            '&.MuiInputLabel-root.Mui-disabled': {
              color: color || 'rgba(0, 0, 0, 0.38)',
              opacity: color ? 0.6 : 1,
            },
          },
        },
        ...props.InputLabelProps,
      }}
      FormHelperTextProps={{
        sx: {
          display: error ? 'block' : 'none',
          ...styles.formHelperText,
        },
      }}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFocus: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
