import React from 'react';
import Box from '@mui/material/Box';

export default function withStyledButtonText(Component) {
  return (props) => (
      <>
        <Box
          sx={{
            display: 'none',
            '& + button': {
              '& h1, h2, h3, h4, p, small': {
                m: 0,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
              '& h1': {
                lineHeight: 1.4,
              },
              '& h2': {
                lineHeight: 1.6,
              },
              '& h3': {
                lineHeight: 1.8,
              },
              '& h4': {
                lineHeight: 2,
              },
            },
          }}
        />
        <Component {...props} />
      </>
  );
}
