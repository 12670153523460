import React from 'react';
import PropTypes from 'prop-types';
import { RWebShare } from 'react-web-share';
import Dropdown from './components/Dropdown';
import { Share2 } from 'react-feather';
import { Container, ShareIconWrapper } from './style';

const SocialShare = ({ shareUrl, supportWebshare, withProgressBar }) => {
  return (
    <Container withProgressBar={withProgressBar}>
      {supportWebshare ? (
        <RWebShare
          data={{
            url: shareUrl,
          }}
        >
          <ShareIconWrapper data-testid="SocialShare/ShareButton">
            <Share2 />
          </ShareIconWrapper>
        </RWebShare>
      ) : (
        <Dropdown shareUrl={shareUrl} />
      )}
    </Container>
  );
};

SocialShare.propTypes = {
  shareUrl: PropTypes.string.isRequired,
  supportWebshare: PropTypes.bool,
  withProgressBar: PropTypes.bool,
};

export default SocialShare;
