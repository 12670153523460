import React from 'react';
import PropTypes from 'prop-types';

function UploadProgress({
  percentageRef,
  progressBarRef,
  cancelUpload,
}) {
  return (
    <div className="upload-state">
      <div className="upload-state__text-container">
        <h3 className="upload-state__text">Uploading...</h3>
        <span
          ref={percentageRef}
          className="upload-state__percentage"
          data-testid="UploadProgress/Percentage"
        >
          0%
        </span>
      </div>
      <div className="upload-state__progress-bar">
        <span
          ref={progressBarRef}
          className="upload-state__progress"
          data-testid="UploadProgress/ProgressBar"
        ></span>
      </div>
      <div className="upload-state__button-container">
        <button
          type="button"
          className="upload-state__cancel-button"
          onClick={cancelUpload}
        />
      </div>
      <div className="upload-state__text">Cancel</div>
    </div>
  );
}

UploadProgress.propTypes = {
  percentageRef: PropTypes.object,
  progressBarRef: PropTypes.object,
  cancelUpload: PropTypes.func,
};

export default UploadProgress;
