import React from 'react';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from './components/Button';

const findButton = (buttons, direction) =>
  buttons.find((b) => b.direction === direction);

export default function NavigationButtons({
  buttons,
  isVisible,
  isPreview,
  skippable,
  controllerName,
  staticPosition,
  isMultiSelect,
  withMediaBackground,
  contentLayout,
  responseIsPublished,
  backPath,
}) {
  if (!isVisible) {
    return null;
  }

  const [backIsDisabled, setBackIsDisabled] = React.useState(isPreview);
  const [forwardIsDisabled, setForwardIsDisabled] = React.useState(isPreview);
  const backButton = findButton(buttons, 'back');
  const forwardButton = findButton(buttons, 'forward');

  React.useEffect(() => {
    if (!backPath) {
      setBackIsDisabled(true);
    }

    const blockDoesNotHaveCompletedState = [
      'ugc_media_responses',
      'ugc_responses',
      'survey_responses',
    ].includes(controllerName);
    const mcOptionWasNotSelected =
      controllerName === 'multiple_choice_responses' &&
      !isMultiSelect &&
      !responseIsPublished;

    if (blockDoesNotHaveCompletedState || mcOptionWasNotSelected) {
      setForwardIsDisabled(true);
    }
  }, []);

  const handleForwardClick = () => {
    switch (controllerName) {
      case 'data_responses':
      case 'donation_amount_responses':
      case 'no_content_responses':
      case 'ugc_text_responses':
        const form = document.forms[0];

        form.requestSubmit();
        break;
      case 'credit_card_responses':
        document.querySelector('[aria-label="credit card submit"]').click();

        break;
      case 'multiple_choice_responses':
        const selectedOption = document.querySelector('[data-selected="true"]');
        const ctaButton = document.querySelector(
          '[data-testid="SelectBoxSelector/submit"]',
        );

        if (selectedOption) {
          selectedOption.click();
        } else if (ctaButton) {
          ctaButton.click();
        }
        break;
    }
  };

  const handleBackClick = () => {
    if (!backIsDisabled) {
      Turbo.visit(backPath);
    }
  };

  return (
    <Box
      aria-label="navigation buttons section"
      sx={{
        position: staticPosition ? 'static' : 'absolute',
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        mt: skippable ? -1 : 1.5,
        '@media (min-width: 961px)':
          withMediaBackground && contentLayout !== 'hug_content'
            ? { position: 'static' }
            : {},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1,
          px: 1,
          '@media (min-width: 599px)': {
            maxWidth: 470,
          },
        }}
      >
        <Button
          aria-label="go back"
          disabled={backIsDisabled}
          attributes={backButton}
          onClick={handleBackClick}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          aria-label="go forward"
          attributes={forwardButton}
          disabled={forwardIsDisabled}
          onClick={handleForwardClick}
        >
          <ChevronRightIcon />
        </Button>
      </Box>
    </Box>
  );
}
