import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUICheckbox from '@mui/material/Checkbox';
import { getBooleanFromString } from '../utils';

export default function Checkbox({
  name,
  label,
  cssStyle,
  required,
  disabled,
  defaultValue,
  checkboxHidden,
}) {
  const booleanDefaultValue = getBooleanFromString(defaultValue);
  const [value, setValue] = React.useState(booleanDefaultValue);
  const color = cssStyle.color;

  React.useEffect(() => {
    setValue(booleanDefaultValue);
  }, [defaultValue]);

  return (
    <>
      <FormControlLabel
        sx={{
          '&.MuiFormControlLabel-root': {
            minHeight: 45,
            boxSizing: 'content-box',
            m: 0,
            ...cssStyle,
          },
          '&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled': {
            color: color || '#fff',
          },
          '& .MuiFormControlLabel-label': {
            width: '100%',
            color: color || '#fff',
            letterSpacing: 'normal',
            '& p, h1, h2, h3, h4, caption': {
              m: 0,
            },
            '& a, & a:hover': {
              color: color || 'var(--link-color)',
            },
          },
          '& .MuiSvgIcon-root': {
            color: color || 'var(--primary-color)',
          },
          '& .MuiButtonBase-root.MuiCheckbox-root': {
            pl: 0,
            '&:hover': {
              bgcolor: 'transparent',
            },
          },
        }}
        required={required}
        disabled={disabled || checkboxHidden}
        control={
          <MUICheckbox
            sx={{ display: checkboxHidden ? 'none' : 'inline-flex' }}
            checked={value}
            disableRipple
            onChange={(e) => setValue(e.target.checked)}
          />
        }
        label={label}
      />
      <input type="hidden" name={name} value={value} />
    </>
  );
}
