import styled from 'styled-components';

export const Textarea = styled.textarea`
  font-weight: 300;
  font-size: 1.563rem;
  line-height: 2rem;
  padding-left: unset;
  padding-right: unset;
  resize: none;
  min-height: 200px;
  overflow: hidden;
  background-color: #0000;
  color: ${(props) => props.color};
  border: 0;
  box-shadow: none;

  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.7;
  }

  &:focus {
    background-color: #0000;
    color: ${(props) => props.color};
    border: 0;
    box-shadow: none;
  }

  &:disabled {
    background-color: #0000;
    opacity: 1;

    &:hover {
      border: 0;
    }
  }
`;
