import React from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Range, Transforms } from 'slate';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MarkButton from '../../../BlockForm/components/TitleSection/components/MarkButton';
import { Divider } from '../../../BlockForm/components/TitleSection/components/ButtonsContainer/style';
import HighlightPicker from '../../../BlockForm/components/TitleSection/components/HighlightPicker';
import ColorPicker from '../../../BlockForm/components/TitleSection/components/ColorPicker';
import BlockButton from '../../../BlockForm/components/TitleSection/components/BlockButton';
import Select from '../../../BlockForm/components/TitleSection/components/Select';
import LetterSettings from '../../../BlockForm/components/TitleSection/components/LetterSettings';
import ParagraphButton from '../../../BlockForm/components/TitleSection/components/ParagraphButton';
import useHorizontalScroll from '../../../hooks/useHorizontalScroll';
import ResponderLinksButton from '../../../BlockForm/components/TitleSection/components/ResponderLinksButton';
import { getMobileOS } from '../../../../utils';
import { FONT_WEIGHT_MENU_ITEMS } from '../../../../contexts/BlockContext';

const paletteColors = [
  '#35343e',
  '#000000',
  '#dd5571',
  '#eba845',
  '#4bb56f',
  '#3d89ae',
  '#114761',
  '#9040ac',
].map((color) => ({ color, name: color }));

function ResponderHoveringToolbar() {
  const [open, setOpen] = React.useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState('top-start');

  const editor = useSlate();
  const { selection } = editor;

  const {
    scrollContainerRef,
    handleScroll,
    isAtStart,
    isAtEnd,
  } = useHorizontalScroll();

  const closeToolbar = () => {
    setOpen(false);
  };

  const openToolbar = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    if (popoverIsOpen) {
      return;
    }
    closeToolbar();
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (getMobileOS() === 'Android') {
      setPlacement('bottom-start');
    }
  }, []);

  React.useEffect(() => {
    if (popoverIsOpen) {
      openToolbar();
      return;
    }
    if (
      !selection ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      closeToolbar();
      setAnchorEl(null);
    } else {
      if (!anchorEl) {
        const [node] = Editor.node(editor, selection);
        const selectedEl = ReactEditor.toDOMNode(editor, node);
        const getBoundingClientRect = () => {
          return selectedEl.getBoundingClientRect();
        };

        openToolbar();
        setAnchorEl({ getBoundingClientRect, nodeType: 1 });
      }
    }
  }, [selection, popoverIsOpen]);

  const handleParagraphButtonPopover = (isOpen) => {
    if (!isOpen) {
      closeToolbar();
      Transforms.collapse(editor, { edge: 'end' });
    }
    setPopoverIsOpen(isOpen);
  };

  return (
    <Popper
      sx={{
        zIndex: 10,
      }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      disablePortal
    >
      <Box
        sx={{
          display: 'flex',
          maxWidth: '430px',
          overflowX: 'auto',
          overflowY: 'hidden',
          height: 56,
          pl: isAtStart ? '12px' : 0,
          pr: isAtEnd ? '12px' : 0,
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0px 0px 16px rgba(51, 51, 51, 0.12)',
          zIndex: 1,
          '&::before': {
            content: isAtStart ? 'none' : '""',
            position: 'sticky',
            left: 0,
            bottom: 0,
            height: 56,
            width: 12,
            background:
              'linear-gradient(270deg, rgba(229, 229, 229, 0.12) 0%, #bfbfbf 100%)',
          },
          '&::after': {
            content: isAtEnd ? 'none' : '""',
            position: 'sticky',
            mr: '-100%',
            right: 0,
            bottom: 0,
            height: 56,
            width: 12,
            background:
              'linear-gradient(90deg, rgba(229, 229, 229, 0.12) 0%, #bfbfbf 100%)',
          },
        }}
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <ClickAwayListener
          onClickAway={handleClickAway}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Select
              onPopover={setPopoverIsOpen}
              menuItems={FONT_WEIGHT_MENU_ITEMS}
              variant="fontWeight"
              defaultValue={400}
              ariaLabel="font weight select"
            />
            <ColorPicker
              onPopover={setPopoverIsOpen}
              paletteColors={paletteColors}
            />
            <HighlightPicker
              onPopover={setPopoverIsOpen}
              paletteColors={paletteColors}
            />
            <ParagraphButton onPopover={handleParagraphButtonPopover} />
            <Divider />
            <MarkButton format="bold" />
            <MarkButton format="italic" />
            <MarkButton format="strikethrough" />
            <MarkButton format="underline" />
            <Divider />
            <BlockButton format="bulleted-list" onClick={closeToolbar} />
            <BlockButton format="numbered-list" onClick={closeToolbar} />
            <Divider />
            <BlockButton format="left" />
            <BlockButton format="center" />
            <BlockButton format="right" />
            <Divider />
            <ResponderLinksButton onPopover={setPopoverIsOpen} />
            <Divider />
            <LetterSettings onPopover={setPopoverIsOpen} />
          </Stack>
        </ClickAwayListener>
      </Box>
    </Popper>
  );
}

export default ResponderHoveringToolbar;
