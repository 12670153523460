import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import { createEditor, Transforms } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { withMention } from '../../SlateElements/components/Mention';
import { withLinks } from '../../BlockForm/components/TitleSection/components/LinksButton/utils';
import ResponderHoveringToolbar from '../../SlateElements/components/ResponderHoveringToolbar';
import {
  buildSlateElements,
  elementsToText,
} from '../../SlateElements/components/utils';
import Element from '../../SlateElements/components/Element';
import Leaf from '../../SlateElements/components/Leaf';
import { Input } from '../../SlateElements/components/EditableMentionsInput/style';
import { TextField } from '../TextField';
import { slateToHtml } from '../../BlockForm/components/TitleSection/utils';

const SlateInput = React.forwardRef(
  ({ renderElement, renderLeaf, ...rest }, ref) => {
    return (
      <Input
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        slateInput="true"
        {...rest}
      />
    );
  },
);

export default function RichTextField(props) {
  const [elements, setElements] = React.useState(
    _isObject(props.defaultValue)
      ? JSON.parse(props.defaultValue.titleElements)
      : buildSlateElements(props.defaultValue),
  );
  const firstRender = React.useRef(true);

  const editor = React.useMemo(
    () => withMention(withLinks(withHistory(withReact(createEditor())))),
    [],
  );

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      Transforms.delete(editor, { at: [0] });
      Transforms.insertNodes(editor, buildSlateElements(props.defaultValue));
    }
  }, [props.defaultValue]);

  const renderElement = React.useCallback((elementProps) => {
    return <Element {...elementProps} editor={editor} />;
  }, []);
  const renderLeaf = React.useCallback((props) => <Leaf {...props} />, []);

  const textValue = React.useMemo(() => {
    return elementsToText(elements);
  }, [elements]);

  const handleValueChange = (elements) => {
    setElements(elements);
  };

  return (
    <Slate editor={editor} value={elements} onChange={handleValueChange}>
      <TextField
        multiline
        InputLabelProps={{
          shrink: !_isEmpty(textValue),
        }}
        {...props}
        InputProps={{
          inputComponent: SlateInput,
          inputProps: {
            renderLeaf,
            renderElement,
            readOnly: props.disabled,
          },
        }}
      />
      <ResponderHoveringToolbar />
      {textValue ? (
        <>
          <input
            type="hidden"
            name={`${props.name}[title]`}
            value={elementsToText(elements)}
          />
          <input
            type="hidden"
            name={`${props.name}[title_html]`}
            value={slateToHtml(elements)}
          />
          <input
            type="hidden"
            name={`${props.name}[title_elements]`}
            value={JSON.stringify(elements)}
          />
        </>
      ) : (
        <input type="hidden" name={props.name} />
      )}
    </Slate>
  );
}
